import { OpportunityType } from '@ec/types'
import Skeleton from 'react-loading-skeleton'
import TagIcon from '../../icons/tagIcon'

type PropTypes = {
  opportunityTypes?: OpportunityType[]
  isLoading?: boolean
}

const OpportunityTags = ({
  opportunityTypes,
  isLoading,
}: PropTypes) => {
  return (
    <div className="w-full">

      <div className="flex flex-wrap gap-6 text-text-gray text-sm">
        <div className="min-w-[32px] h-8 relative lg:mt-0.5">
          <TagIcon className="absolute top-0 left-1 w-7 h-7 z-10" />
          <div className="absolute bottom-0 right-0 w-[22px] h-[22px] bg-primary-yellow" />
        </div>

        {
          isLoading
            ? (
              <>
                <Skeleton className="px-4 py-2.5" width={100} height={40} />
                <Skeleton className="px-4 py-2.5" width={160} height={40} />
              </>
            )
            : opportunityTypes && opportunityTypes.map((type) => (
              <p className="px-4 py-2.5 bg-off-green font-semibold rounded-md">{type.name}</p>
            ))
        }
      </div>

    </div>
  )
}

export default OpportunityTags