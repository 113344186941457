import { gql } from '@apollo/client'

export const LEAVE_OPPORTUNITY = gql`
    mutation LeaveOpportunity($submission_id: ID!) {
        leaveOpportunity(submission_id: $submission_id) {
            status
        }
    }
`

export const CREATE_OPPORTUNITY = gql`
    mutation ($input: CreateOpportunityInput!) {
        createOpportunity(input: $input) {
            id
            slug
        }
    }
`

export const UPDATE_OPPORTUNITY = gql`
    mutation ($id: ID!, $input: UpdateOpportunityInput!) {
        updateOpportunity(id: $id, input: $input) {
            id
            slug
        }
    }
`

export const CANCEL_OPPORTUNITY = gql`
    mutation CancelOpportunity($opportunity_id: ID! $input: CancelOpportunityInput!) {
        cancelOpportunity(opportunity_id: $opportunity_id, input: $input) {
            status
        }
    }
`

export const SUBMIT_OPPORTUNITY = gql`
    mutation ($id: ID!) {
        submitOpportunity(id: $slug) {
            id
            slug
        }
    }
`