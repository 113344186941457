import { ReactNode } from 'react'
import GoogleMapReact, { Coords } from 'google-map-react'

type PropTypes = {
  children?: ReactNode
  center: Coords
  zoom: number
  onDragEnd?: (map: any) => void
}

const Map = ({ children, center, zoom = 14, onDragEnd }: PropTypes) => {
  return (
    <GoogleMapReact
      bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? '' }}
      defaultCenter={center}
      defaultZoom={zoom}
      onDragEnd={onDragEnd}
    >
      {children}
    </GoogleMapReact>
  )
}

export default Map