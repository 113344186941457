import { useMutation } from '@apollo/client'
import { RESET_PASSWORD } from '@ec/apollo/src/mutations/auth'
import { Button, Input, Toast } from '@ec/ui'
import { useEffect, useMemo } from 'react'
import { useNotifier } from 'react-headless-notifier'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { login } from 'slices/auth'

type ResetPasswordProps = {
  email: string
  token: string | null
  password: string
  password_confirmation: string
}

const ResetPasswordPage = () => {
  const search = useLocation().search
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const preDeterminedEmail = searchParams.get('email')
  const { notify } = useNotifier()

  const token = useMemo(() => searchParams.get('token'), [search])

  const { register, handleSubmit, watch, setValue, formState: { errors } } = useForm<ResetPasswordProps>({
    defaultValues: {
      password: '',
      password_confirmation: '',
      token: token,
    },
  })
  
  const doPasswordsMatch = watch('password_confirmation') === watch('password')
  
  const [resetPasswordMutation, { data: resetData, error: isResetError, loading: isResetLoading }] = useMutation(RESET_PASSWORD)

  const onSubmit: SubmitHandler<ResetPasswordProps> = (form) => {
    resetPasswordMutation({ 
      variables: { 
        email: form.email,
        password: form.password,
        passwordConfirmation: form.password_confirmation,
        token: form.token,
      }, 
    })
  }

  useEffect(() => {
    if (resetData?.resetPassword) {
      dispatch(login(resetData?.resetPassword))
      notify(<Toast type='success' message='Your password has successfully been updated. Please log in again to continue.' />)
      navigate('/login')
    }
  }, [resetData])

  useEffect(() => {
    if (isResetError) {
      notify(<Toast type='error' message='We are unable to update your password at this time, please try again later' />)
    }
  }, [isResetError])

  useEffect(() => {
    if (preDeterminedEmail) {
      setValue('email', preDeterminedEmail)
    }
  }, [])

  return (
    <>
      <div className="flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">Reset your password</h2>
          <p className="mt-3 text-gray-500 text-sm mx-auto text-center">Confirm your email and new password to recover your account</p>
        </div>
 
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 sm:rounded-lg sm:px-10">

            <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
              <Input
                label="Email"
                value={preDeterminedEmail ?? undefined}
                disabled={preDeterminedEmail ? true : false} 
                required
                {...register('email')}
                expanding
              />

              <Input 
                label="Password"
                type="password"
                required
                error={errors.password?.message}
                {...register('password', {
                  required: {value: true, message: 'This field is required'},
                  minLength: {value: 8, message: 'Passwords must contain at least 8 characters'},
                })}
              />
              
              <div>
                <Input 
                  label="Confirm Password"
                  type="password"
                  required
                  error={errors.password_confirmation?.message}
                  {...register('password_confirmation', {
                    required: {value: true, message: 'This field is required'},
                  })}
                />
                { 
                  (watch('password').length > 1 && watch('password_confirmation').length > 1) 
                    ? doPasswordsMatch
                      ? <p className="text-green-600 text-sm">These passwords match</p>
                      : <p className='text-red-600 text-sm'>These passwords do not match</p>
                    : <></>
                }
              </div>

              <Button 
                block
                isLoading={isResetLoading}
                disabled={!doPasswordsMatch}
              >
                Confirm Change
              </Button>
              
              <div className="cf-turnstile" data-sitekey={process.env.REACT_APP_CLOUDFLARE_TURNSTILE_SITE_KEY}></div>
            </form>
          </div>
        </div>

      </div>
    </>
  )
}

export default ResetPasswordPage