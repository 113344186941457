import { useQuery } from '@apollo/client'
import { Button, Container, FeaturedOpportunitiesSwiper, OpportunitiesGrid, OpportunitySearch, Square2Icon, Title } from '@ec/ui'
import { GET_OPPORTUNITIES, GET_OPPORTUNITY_FILTERS, GET_SPONSORED_OPPORTUNITIES } from '@ec/apollo/src/queries/opportunities'
import { OpportunityFilters, OpportunityQuery, SponsoredOpportunityQuery } from '@ec/types'
import { Subtitle } from '@ec/ui/src/components/Headings'
import { useEffect, useState } from 'react'
import { OpportunitySearchProps } from '@ec/ui/src/components/Search/OpportunitySearch'
import OpportunityMap, { MapBounds } from 'components/OpportunityMap'
import { MapPinIcon } from '@heroicons/react/24/outline'

const OpportunitiesIndex = () => {
  const [isSearchLoading, setIsSearchLoading] = useState<boolean>(false)
  const [isMapView, setIsMapView] = useState<boolean>(false)
  const [userOrigin, setUserOrigin] = useState<any>()
  const [isFetchMoreLoading, setIsFetchMoreLoading] = useState<boolean>(false)
  const getUserLocation = (callback, errorCallback) => {
    if (navigator.geolocation) {
      return navigator.geolocation.getCurrentPosition(callback, errorCallback)
    }

    return errorCallback
  }

  useEffect(() => {
    getUserLocation((position) => {
      setUserOrigin({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      })
    },
    () => {
      // Default to London if location cannot be retrieved from the user
      setUserOrigin({
        lat: 51.509865,
        lng: -0.118092,
      })
    })
  }, [])

  const { data: opportunityFilterQuery, loading: isOpportunityFilterLoading } = useQuery<{
    opportunityFilters: OpportunityFilters
  }>(GET_OPPORTUNITY_FILTERS, {
    variables: {
      fromOrigin: userOrigin,
    },
  })

  const {
    data: opportunityQuery,
    loading: isOpportunitiesLoading,
    fetchMore: fetchMoreOpportunities,
    refetch: refetchOpportunities,
    variables: queryVariables,
  } = useQuery<{ opportunities: OpportunityQuery }>(GET_OPPORTUNITIES, 
    {
      variables: {
        first: 21,
        page: 1,
        filters: {
          bounds: {
            top: undefined,
            bottom: undefined,
            left: undefined,
            right: undefined,
          },
          search: undefined,
          types: undefined,
          fromDate: undefined,
          toDate: undefined,
          withinDistance: undefined,
          fromOrigin: undefined,
        },
      },
    },
  )

  const {
    data: sponsoredOpportunityQuery,
    loading: isSponsoredOpportunitiesLoading,
  } = useQuery<{ sponsoredOpportunities: SponsoredOpportunityQuery }>(GET_SPONSORED_OPPORTUNITIES,
    {
      variables: {
        first: 3,
      },
    },
  )

  const submitSearch = (form: OpportunitySearchProps) => {
    setIsSearchLoading(true)

    refetchOpportunities({
      first: 30,
      page: 1,
      filters: {
        bounds: {
          top: undefined,
          bottom: undefined,
          right: undefined,
          left: undefined,
        },
        search: form.search === '' ? undefined : form.search,
        types: form.types?.length === 0 ? undefined : form.types,
        fromDate: form.fromDate ?? undefined,
        toDate: form.toDate ?? undefined,
        withinDistance: form.withinDistance ?? undefined,
        fromOrigin: Object.values(userOrigin),
      },
    }).then(() => setIsSearchLoading(false))
  }

  const handleOnMapDragEnd = (map: MapBounds) => {
    refetchOpportunities({
      first: 30,
      page: 1,
      filters: {
        bounds: {
          top: map.top,
          bottom: map.bottom,
          right: map.right,
          left: map.left,
        },
      },
    })
  }

  return (
    <div>

      {/* Search Box */}
      <div className="searchbox-container relative group flex mx-auto min-h-[200px] lg:min-h-[250px] max-w-[1200px] lg:rounded-md w-full">
        <div className="absolute inset-0 bg-primary-blue bg-opacity-[87%] lg:rounded-md"></div>
        <div className="px-4 lg:px-0 m-auto max-w-[880px] w-full relative z-10">
          <Title className="!text-white text-center mb-24 lg:text-3xl text-2xl !leading-[50px]">Search Volunteering Opportunities</Title>
        </div>
      </div>

      {/* Search Filters */}
      <div className="lg:-mt-32 -mt-28 lg:pb-8 pb-14 sticky top-0 max-w-[880px] mx-auto z-20 pt-2 px-2 lg:px-0">
        <OpportunitySearch
          isLoading={isSearchLoading || isOpportunityFilterLoading}
          distanceRange={opportunityFilterQuery?.opportunityFilters.distances}
          availableTypes={opportunityFilterQuery?.opportunityFilters.opportunityTypes}
          onSearch={(form) => submitSearch(form)}
        />
      </div>

      {/* Search Filters */}
      <Container className="flex px-4 mt-0 lg:justify-end lg:mt-8 lg:px-0">
        <div className="relative z-10 flex my-4">
          <div className="flex flex-wrap gap-4 ml-auto">
            <Button variant="secondary" onClick={() => setIsMapView(false)} ariaLabel="Opportunity List">
              <div className="flex items-center gap-2">
                <Square2Icon className={`w-6 h-6 ${!isMapView ? 'text-primary-blue' : 'text-placeholder'}`} />
                <span className="font-medium">List View</span>
              </div>
            </Button>
            <Button variant="secondary" onClick={() => setIsMapView(true)} ariaLabel="Map">
              <div className="flex items-center gap-2">
                <MapPinIcon className={`w-6 h-6 ${isMapView ? 'text-primary-blue' : 'text-placeholder'}`} />
                <span className="font-medium">Map View</span>
              </div>
            </Button>
          </div>
        </div>
      </Container>

      {
        isMapView ?
          <>
            {/* Wide View */}
            <div className="relative hidden w-full h-full min-h-0 gap-20 px-4 mx-auto lg:flex lg:px-10">
              <div className="flex-shrink-0 hidden lg:block max-w-[400px] 2xl:max-w-[834px] w-full">
                <Title>Volunteer Opportunities</Title>
                <OpportunitiesGrid
                  altText="There are no new Opportunities in this area right now, come back soon!"
                  alwaysShowSkeletons
                  isLoading={isOpportunitiesLoading}
                  opportunities={opportunityQuery?.opportunities.data}
                  gridColsClassNames="grid-cols-1 2xl:grid-cols-2"
                  keyPrefix="map-opportunities-"
                />
              </div>
              <div className="relative w-full h-full">
                <OpportunityMap
                  opportunities={opportunityQuery?.opportunities.data}
                  onMapDragEnd={(map) => handleOnMapDragEnd(map)}
                />
              </div>
            </div>
            {/* Mobile View */}
            <div className="relative block w-full h-full px-4 mx-auto mt-4 lg:hidden lg:px-10 lg:mt-0">
              <OpportunityMap
                className="mt-8"
                opportunities={opportunityQuery?.opportunities.data}
                onMapDragEnd={(map) => handleOnMapDragEnd(map)}
              />
              <OpportunitiesGrid
                altText="There are no new Opportunities in this area right now, come back soon!"
                alwaysShowSkeletons
                isLoading={isOpportunitiesLoading}
                opportunities={opportunityQuery?.opportunities.data}
                gridColsClassNames="grid-cols-1 2xl:grid-cols-2"
                keyPrefix="map-opportunities-"
              />
            </div>
          </>
          :
          <>
            {/* Featured Opportunities */}
            { sponsoredOpportunityQuery?.sponsoredOpportunities.data && sponsoredOpportunityQuery?.sponsoredOpportunities.data.length >= 1 &&
              <>
                <Container>
                  <div>
                    <Title>Promoted Opportunities</Title>
                    <Subtitle>The latest opportunities available</Subtitle>
                  </div>
                </Container>

                <div className="w-full mt-8">
                  <FeaturedOpportunitiesSwiper
                    isLoading={isSponsoredOpportunitiesLoading}
                    opportunities={sponsoredOpportunityQuery?.sponsoredOpportunities.data.map((sponsor) => sponsor.opportunity)}
                  />
                </div>
              </>
            }

            {/* New Opportunities */}
            <Container className="mt-6 lg:mt-14">
              <div>
                <Title>Volunteer Opportunities</Title>
                <Subtitle>The latest opportunities available</Subtitle>
              </div>
            </Container>

            <div className="mt-12 max-w-[1268px] mx-auto pb-24 px-4 lg:px-0">

              <OpportunitiesGrid
                keyPrefix="opportunities-grid-"
                isLoading={isOpportunitiesLoading}
                opportunities={opportunityQuery?.opportunities.data}
              />

              {
                opportunityQuery?.opportunities.paginatorInfo?.currentPage === opportunityQuery?.opportunities.paginatorInfo?.lastPage &&
                queryVariables && Object.values(queryVariables.filters.bounds).some((bound) => bound !== undefined)
                &&
                <div className="flex mt-32 ">
                  <div className="mx-auto">
                    <p className="text-center text-text-gray-light">You have filtered this search by location using the <span className="underline cursor-pointer text-primary-blue" onClick={() => setIsMapView(true)}>Map</span>, do you want to reset your filters?</p>
                    <div className="flex justify-center mt-8">
                      <Button variant="secondary" onClick={() => submitSearch({})}>Yes, clear my filters</Button>
                    </div>
                  </div>
                </div>
              }
              { opportunityQuery?.opportunities.paginatorInfo?.currentPage !== opportunityQuery?.opportunities.paginatorInfo?.lastPage &&
              <div className='relative flex items-center justify-center w-full mt-10'>
                <Button
                  isLoading={isFetchMoreLoading}
                  onClick={async () => {
                    setIsFetchMoreLoading(true)

                    fetchMoreOpportunities({
                      variables: {
                        page: (opportunityQuery?.opportunities.paginatorInfo?.currentPage ?? 1) + 1,
                      },
                      updateQuery: (prev, { fetchMoreResult }) => {
                        if (!fetchMoreResult) {
                          return prev
                        }
            
                        return {
                          ...prev,
                          opportunities: {
                            ...fetchMoreResult.opportunities,
                            data: [...prev?.opportunities?.data ?? [], ...fetchMoreResult.opportunities.data],
                          },
                        }
                      },
                    }).then(() => setIsFetchMoreLoading(false))
                  }
                  }
                >
                  Fetch More
                </Button>
              </div>
              }
            </div>
          </>
      }

    </div>
  )
}


export default OpportunitiesIndex