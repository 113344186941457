import { useMutation } from '@apollo/client'
import { CREATE_PERSONAL_DATA_EXPORT, UPDATE_VOLUNTEER } from '@ec/apollo/src/mutations/auth'
import { UserType } from '@ec/types'
import { Button, DateInput, DeleteAccountModal, Heading, Input, Toast } from '@ec/ui'
import dayjs from 'dayjs'
import useErrorHandler from 'hooks/UseErrorHandler'
import { useEffect, useState } from 'react'
import { useNotifier } from 'react-headless-notifier'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { updateAuthUser } from 'slices/auth'
import { RootState } from 'store'

type AccountSettingsForm = {
  name: string
  email: string
  date_of_birth: string
}

const AccountSettingsPage = () => {
  const [isAccountDeletionModalOpen, setIsAccountDeletionModalOpen] = useState(false)
  const dispatch = useDispatch()
  const { notify } = useNotifier()
  const errorHandler = useErrorHandler()

  const { user } = useSelector((state: RootState) => ({
    user: state.auth.user,
  }))

  const [updateAccountDetailsMutation, { data, loading, error }] = useMutation<{ updateVolunteerAccountDetails: UserType }>(UPDATE_VOLUNTEER)

  const { register, handleSubmit, setError, getValues, setValue, reset, formState: { errors }, clearErrors } = useForm<AccountSettingsForm>({
    defaultValues: {
      date_of_birth: dayjs().toISOString(),
    },
  })

  const [createPersonalDataExportMutation, {
    data: personalDataExportData,
    loading: personalDataExportLoading,
    error: personalDataExportError,
  }] = useMutation(CREATE_PERSONAL_DATA_EXPORT)

  const onSubmit: SubmitHandler<AccountSettingsForm> = (form) => {
    updateAccountDetailsMutation({
      variables: {
        input: {
          name: form.name,
          email: form.email,
          date_of_birth: dayjs(form.date_of_birth).format('YYYY-MM-DD'),
        },
      },
    })
  }

  const onDeleteAccountErrorHandler = () => {
    notify(<Toast title="Request Unsuccessful" message="You may have active opportunity submissions or are actively participating in an opportunity. Please check this and try again." type="error" />)
  }

  const onDeleteAccountSuccessHandler = () => {
    notify(<Toast title="Request Successful" message="Your request to delete your account information has been submitted. Please keep an eye on your email inbox for updates on this." type="success" />)
  }

  const requestDataExport = () => {
    createPersonalDataExportMutation({
      variables: {
        id: user?.id,
      },
    })
  }

  useEffect(() => {
    if (user) {
      reset({
        name: user?.name,
        email: user?.email,
        date_of_birth: user?.date_of_birth ?? dayjs().toISOString(),
      })
    }
  }, [user])

  useEffect(() => {
    if (personalDataExportData) {
      notify(<Toast title="Request Sent" message="A link to download your data should reach your inbox shortly" />)
    }
  }, [personalDataExportData])

  useEffect(() => {
    if (personalDataExportError) {
      notify(<Toast title="Data Request Unsuccessful" message="Please try again later" type="error" />)
    }
  }, [personalDataExportError])

  useEffect(() => {
    if (data?.updateVolunteerAccountDetails) {
      dispatch(updateAuthUser(data.updateVolunteerAccountDetails))
      notify(<Toast title="Update Successful" message="Your details have been saved" />)
    }
  }, [data])

  useEffect(() => {
    if (error) {
      errorHandler(error, setError)
      notify(<Toast title="Update Unsuccessful" message="Your details have not been saved" type="error" />)
    }
  }, [error])

  return (
    <>

      <Heading className="mb-5">Account Settings</Heading>

      <hr className="bg-divider" />

      <form onSubmit={(e) => {
        e.preventDefault()
        clearErrors()
        handleSubmit(onSubmit)(e)
      }} className="max-w-[50rem]">

        <div className="flex flex-col gap-8 mt-8">

          <div className="flex lg:flex-row flex-col lg:justify-between lg:items-center">
            <p className="text-text-gray">Name</p>
            <Input
              {...register('name')}
              width="max-w-md"
              error={errors.name?.message}
            />
          </div>

          <div className="flex lg:flex-row flex-col lg:justify-between lg:items-center">
            <p className="text-text-gray">Email</p>
            <Input
              {...register('email')}
              type="email"
              width="max-w-md"
              error={errors.email?.message}
              expanding
            />
          </div>

          <div className="flex lg:flex-row flex-col lg:justify-between lg:items-center">
            <p className="text-text-gray">Date of Birth</p>
            <div className="max-w-md w-full">
              <DateInput value={getValues('date_of_birth')} onChange={(date) => setValue('date_of_birth', date)} error={errors.date_of_birth?.message} />
            </div>
          </div>

        </div>

        <div className="flex justify-end lg:mt-6 mt-10">
          <Button isLoading={loading}>Save Changes</Button>
        </div>

      </form>

      <div className="flex flex-col max-w-[50rem] mt-8">
        <Heading className="mb-5">Download Personal Information</Heading>
        <hr className="bg-divider" />
        <p className="mt-8">To retrieve a file containing all information that is held about you on our site, click the button below. You will receive an e-mail with a link to the file when the data is available for download.</p>
        <div className="flex justify-end lg:mt-6 mt-10">
          <Button isLoading={personalDataExportLoading} onClick={requestDataExport}>Request Download</Button>
        </div>
      </div>

      <div className="flex flex-col max-w-[50rem] mt-8">
        <Heading className="mb-5">Delete Account</Heading>
        <hr className="bg-divider" />
        <p className="mt-8">
          Deleting your account will remove all of your personal and sensitive information from our records.
          If you are currently an active volunteer, you will not be able to delete your account.
        </p>
        <div className="flex justify-end lg:mt-6 mt-10 gap-2">
          <Button variant='danger' onClick={() => setIsAccountDeletionModalOpen(true)}>Request Account Deletion</Button>
        </div>
      </div>

      <DeleteAccountModal
        isOpen={isAccountDeletionModalOpen}
        onClose={() => setIsAccountDeletionModalOpen(false)}
        onError={onDeleteAccountErrorHandler}
        onSuccess={onDeleteAccountSuccessHandler}
      />
    </>
  )
}

export default AccountSettingsPage