import Address from '@ec/types/src/address'

export const shortAddress = (address: Address) => {
  return [
    address.street_address,
    address.city,
    address.postcode,
  ].filter((line) => line).join(', ')
}

export const regionalAddress = (address: Address) => {
  return [
    address.city,
    address.county,
  ].filter((line) => line).join(', ')
}