import Image from '../../Image'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { Link } from 'react-router-dom'
import OpportunityTagsExcerpt from '../../OpportunityTagsExcerpt'
import { OpportunityType } from '@ec/types'
import ClockIcon from '../../../icons/clockIcon'

type PropTypes = {
  title?: string
  heading?: string
  content?: string
  imageSource?: string
  imageAlt?: string
  contentMadeAt?: string
  tags?: OpportunityType[],
  href?: string
  isLoading?: boolean
}

const ContentCard = ({
  title,
  heading,
  content,
  imageSource,
  imageAlt = 'uploaded-image',
  contentMadeAt,
  tags,
  isLoading = false,
  href,
}: PropTypes) => {
  // Add relativeTime plugin
  dayjs.extend(relativeTime)

  const InnerContentCard = () => {
    return (
      <div className="w-full mx-auto rounded-md">
        <div className="flex-shrink-0">
          {
            isLoading
              ? <Skeleton height={245} />
              : (
                <div className="relative">
                  {
                    contentMadeAt &&
                    <div className="flex items-center absolute text-sm bg-white pl-3 pr-5 py-1.5 rounded-full right-0 mt-5 mr-5 text-text-gray z-10"><ClockIcon className="h-4 w-4 text-black mr-1.5" />Ends {dayjs(contentMadeAt).fromNow()}</div>
                  }
                  <Image source={imageSource} alt-text={imageAlt} className={`object-cover bg-white rounded-md w-full min-h-[245px] max-h-[245px] border border-gray`} />
                </div>
              )
          }
        </div>
        <div className="pt-5">
          <h1 className="text-xl font-semibold leading-6 group-hover:underline group-focus:underline mb-2">{isLoading ? <Skeleton /> : title}</h1>
          {
            !isLoading ?
            <OpportunityTagsExcerpt tags={tags!} />
            :
            <Skeleton width={140} height={20} />
          }
          <h4 className="mt-2 font-medium text-primary-blue leading-5 group-hover:underline group-focus:underline">{isLoading ? <Skeleton /> : heading}</h4>

          <p className="max-w-[350px] mt-3 text-sm max-h-20 overflow-hidden text-ellipsis text-text-gray-light">
            {
              isLoading
                ? <Skeleton count={2.75} />
                : content
            }
          </p>
        </div>
      </div>
    )
  }

  return (
    <>
      {
        href
          ? <Link className="group focus:outline-none" to={href}><InnerContentCard /></Link>
          : <InnerContentCard />
      }
    </>
  )
}

export default ContentCard