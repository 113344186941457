import { Toast } from '@ec/ui'
import { useNotifier } from 'react-headless-notifier'

const useErrorHandler = () => {
  const { notify } = useNotifier()

  return (error: any, setError: Function) => {
    let validationErrors = error?.graphQLErrors[0]?.extensions?.validation

    if (validationErrors && setError) {
      const fields: string[] = []
      const invalidFields: string[] = Object.keys(validationErrors)

      invalidFields.map((invalidField) => {
        /**
         * This RegEx is meant to Ignore anything before the first '.' if nested - this is for when we use inputs
         * in our mutations or queries with Lighthouse.
         */
        //eslint-disable-next-line
        const regExResult: RegExpMatchArray = invalidField.match('[\\w]*\\.(?<attribute>.*)')!
        const field: string = regExResult.groups?.attribute!

        if (!fields.includes(field)) {
          /**
           * Looks like Lighthouse returns an array of Errors in case a field fails more than one rule.
           * For now the user will just have to just go through each error at a time if that is the case.
           */
          setError(field, { message: validationErrors[invalidField][0] })
          fields.push(field)
        }
        return null
      })
    } else {
      notify(<Toast type="error" message="Something went wrong, try again soon."/>)
    }
  }
}

export default useErrorHandler
