import { ClockIcon } from '../../../icons'
import Skeleton from 'react-loading-skeleton'

type PropTypes = {
  duration?: string,
  subtitle?: string,
  isLoading?: boolean
}

const DurationBanner = ({
  duration,
  subtitle,
  isLoading = false,
}: PropTypes) => {
  return (
    <div className="w-full border border-divider p-2.5 lg:p-5 rounded-[10px]">
      <div className="flex items-center">
        {
          isLoading ?
            <Skeleton height={36} width={40} />
            :
            <div className="bg-muted rounded-md p-[0.6875rem]">
              <ClockIcon className="w-[1.125rem] h-[1.125rem]" />
            </div>
        }

        <div className="flex flex-wrap w-full items-center overflow-x-auto">
          {
            isLoading
              ? <div className="w-full ml-[1.25rem] max-w-[12.5rem]">
                <Skeleton width={200} height={28} className="lg:max-w-[12.5rem] max-w-[5rem]" />
              </div>
              : <p className="font-semibold text-sm lg:text-lg mr-[0.9375rem] ml-[1.25rem]">
                <span>{duration}</span>
              </p>
          }
          {
            (subtitle && !isLoading) &&
            <p className="text-gray lg:text-sm text-xs ml-4">{subtitle}</p>
          }
        </div>
      </div>
    </div>
  )
}

export default DurationBanner