import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import classNames from 'classnames'
import { ExclamationCircleIcon, InformationCircleIcon, QuestionMarkCircleIcon } from '@heroicons/react/20/solid'

type Props = {
  type: 'info' | 'question' | 'danger'
  content: string
  id: string
  className?: string
}

const ToolTipButton = ({type, content, id, className = ''}: Props) => {
  
  const iconProps = {
    id: id,
    className: classNames('cursor-help', className)
  }

  return (
    <>
      {type === 'question' && <QuestionMarkCircleIcon {...iconProps}/>}
      {type === 'info' && <InformationCircleIcon {...iconProps}/>}
      {type === 'danger' && <ExclamationCircleIcon {...iconProps}/>}
      <Tooltip anchorId={id} style={{maxWidth: '25rem'}} content={content}/>
    </>
  )
}

export default ToolTipButton