import { Opportunity, Submission } from '@ec/types'
import { useMutation } from '@apollo/client'
import { LEAVE_OPPORTUNITY } from '@ec/apollo/src/mutations/opportunity'
import { CalendarIcon, MapPinIcon, Modal, SpinnerIcon, ToolTipButton } from '@ec/ui'
import dayjs from 'dayjs'
import { useMemo } from 'react'
import { ClockIcon } from '@heroicons/react/24/outline'

type PropTypes = {
    isOpen: boolean
    onClose: () => void
    opportunity?: Opportunity
    refetch?: () => void
    submission?: Submission
  }

const OpportunityOptionsModal = ({
  isOpen = false,
  refetch,
  onClose,
  opportunity,
  submission,
}: PropTypes) => {
  const [leaveOpportunityMutation, { loading: isLeaveOpportunityLoading }] = useMutation(LEAVE_OPPORTUNITY, {
    variables: {
      submission_id: submission?.id,
    },
    onCompleted: () => {
      onClose()
      if (refetch) {
        refetch()
      }
    },
  })

  const isLeaveLocked = useMemo(() => {
    return dayjs(opportunity?.starts_at).isBefore(dayjs()) ?? false
  }, [opportunity])

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='medium'>
      <div className="flex flex-col gap-2">
        <h1 className='font-bold text-xl'>{opportunity?.title}</h1>
        <div className="flex gap-2">
          <MapPinIcon className="text-primary-blue w-6 h-6" />
          <p className='text-primary-blue'>{opportunity?.street_address}, {opportunity?.county}, {opportunity?.postcode}</p>
        </div>
      </div>

      <hr className='mt-[22px] mb-[33px]' />

      <div className="flex flex-col gap-3 mb-6">
        <div className="flex items-center gap-2">
          <CalendarIcon className="text-primary-dark w-6 h-6" />
          <p className='text-lg font-bold'>
            {dayjs(opportunity?.starts_at).format('dddd, Do MMMM YYYY')}
          </p>
          <p className="text-gray lg:text-sm text-xs whitespace-nowrap ml-4">Start Date</p>
        </div>

        { opportunity?.duration &&
          <div className="flex items-center gap-2">
            <ClockIcon className="text-primary-dark w-6 h-6" />
            <p className='text-lg font-bold'>
              {opportunity?.duration} Hours
            </p>
            <p className="text-gray lg:text-sm text-xs whitespace-nowrap ml-4">Event Duration</p>
          </div>
        }
      </div>

      { opportunity?.manager_instructions &&
        <>
          <div className="flex gap-2 items-center mb-2">
            <h2 className='font-bold text-lg'>Manager Instructions</h2>
            <ToolTipButton
              id='manager-instructions-tooltip'
              content='The instructions that you can use to help you begin your volunteering.'
              className='h-4 text-text-gray-light' type='question'
            />
          </div>
          <p className='text-text-gray-light overflow-y-auto max-h-40'>
            {opportunity?.manager_instructions}
          </p>
        </>
      }

      <button
        className='rounded-md text-red-500 disabled:text-red-300 font-semibold flex items-center mt-10'
        onClick={() => leaveOpportunityMutation() }
        disabled={isLeaveLocked || isLeaveOpportunityLoading}
      >
        Leave Opportunity<SpinnerIcon className={`mx-2 w-3 h-3 ${isLeaveOpportunityLoading ? 'inline' : 'hidden'} `} />
      </button>
    </Modal>

  )
}

export default OpportunityOptionsModal