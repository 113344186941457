import { OpportunityDetails } from '@ec/ui'

const OpportunitySkeletons = () => {
  return (
    <div className="mt-8 space-y-8">
      <OpportunityDetails isLoading={true} />
      <OpportunityDetails isLoading={true} />
      <OpportunityDetails isLoading={true} />
    </div>
  )
}

export default OpportunitySkeletons