import { NetworkStatus, useQuery } from '@apollo/client'
import { Container, OpportunityDetails, Toast } from '@ec/ui'
import { useOnScreen } from 'hooks/UseOnScreen'
import { useEffect, useRef, useState } from 'react'
import { useNotifier } from 'react-headless-notifier'
import { Link } from 'react-router-dom'
import { UserType } from '@ec/types'
import OpportunitySkeletons from './utils/OpportunitySkeletons'
import { GET_USER_SUBMISSIONS } from '@ec/apollo/src/queries/user'
import { TimePeriod } from 'helpers/enums/TimePeriod'

const UpcomingOpportunitiesPage = () => {
  const { notify } = useNotifier()
  const [isRefetchLoading, setIsRefetchLoading] = useState<boolean>(true)

  const infiniteScrollRef = useRef(null)
  const { isIntersecting } = useOnScreen(infiniteScrollRef)

  const { data, loading, error, refetch, networkStatus, fetchMore } = useQuery<{ getAuthUser: UserType }>(GET_USER_SUBMISSIONS, {
    variables: {
      submissionsFirst: 10,
      submissionsPage: 1,
      submissionsFilter: {
        timePeriod: TimePeriod.UPCOMING,
      },
    },
    notifyOnNetworkStatusChange: true,
  })

  // Having to refetch manually as query not refetching on variable change across pages(?)
  useEffect(() => {
    if (!loading) {
      refetch({
        submissionsFirst: 10,
      })
    }
  }, [])

  // Monitor refetch loading separately from normal loading
  useEffect(() => {
    if (networkStatus === NetworkStatus.refetch) {
      setIsRefetchLoading(true)
    }
    if (networkStatus === NetworkStatus.ready || networkStatus === NetworkStatus.error) {
      setIsRefetchLoading(false)
    }
  }, [networkStatus])

  useEffect(() => {
    if (error) {
      notify(<Toast type="error" message="There was a problem finding your future events, try again soon" />)
    }
  }, [error])

  // Handle infinite scrolling
  useEffect(() => {
    if (data?.getAuthUser?.submissions.paginatorInfo?.currentPage === data?.getAuthUser?.submissions.paginatorInfo?.lastPage) {
      return
    }

    if (loading) {
      return
    }

    fetchMore({
      variables: {
        submissionsPage: (data?.getAuthUser?.submissions.paginatorInfo?.currentPage ?? 1) + 1,
      },
    })
  }, [isIntersecting])

  return (
    <Container className="pb-32 relative">

      {
        isRefetchLoading
          ? <OpportunitySkeletons />
          : (
            <div className="mt-8 space-y-8">
              {
                data?.getAuthUser?.submissions?.data && data?.getAuthUser?.submissions.data.length > 0
                  ? data.getAuthUser?.submissions.data.map((submission) => {
                    return (
                      <OpportunityDetails
                        key={`upcoming-opportunities-${submission.opportunity.id}`}
                        opportunity={submission.opportunity} submission={submission}
                        refetch={() => refetch()}
                      />
                    )
                  })
                  : <p className="text-text-gray text-sm">You have no upcoming events at the moment, <Link to="/" className="text-primary-blue hover:underline">explore new opportunities!</Link></p>
              }
            </div>
          )
      }

      <div ref={infiniteScrollRef} className={`${loading && 'hidden'} h-[0.0625rem] w-full absolute bottom-0 inset-x-0`} />

    </Container>
  )
}

export default UpcomingOpportunitiesPage