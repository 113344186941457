import { useMutation } from '@apollo/client'
import { SEND_VERIFICATION_EMAIL } from '@ec/apollo/src/mutations/auth'
import { Button, Toast } from '@ec/ui'
import { useEffect, useState } from 'react'
import { useNotifier } from 'react-headless-notifier'
import { useSelector } from 'react-redux'
import { RootState } from 'store'

const VerifyEmail = () => {
  const { notify } = useNotifier()
  const [verification, setVerificationSent] = useState(false)

  const { user } = useSelector((state: RootState) => ({
    user: state.auth.user,
  }))

  const [sendVerificationEmailMutation, { data, loading, error }] = useMutation(SEND_VERIFICATION_EMAIL)

  useEffect(() => {
    if (data) {
      setVerificationSent(true)
    }
  }, [data])

  useEffect(() => {
    if (error) {
      notify(<Toast title="Error" message="Something went wrong. Please try again later" type="error" />)
    }
  }, [error])

  return (
    <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
          {
            verification ? 'Email Verfication Resent' : 'Verify Your Email Address'
          }
        </h2>
        <p className="mt-3 text-gray-500 text-sm mx-auto text-center">
          {
            verification ?
              'We have sent a verfication email to the address listed below'
              :
              'To continue using your Go Volunteering account, please verify your email address'
          }
        </p>
        <p className="text-xl font-bold text-center mt-8">{user!.email}</p>
      </div>

      {
        !verification &&
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="py-8 px-4 sm:rounded-lg sm:px-10">
            <Button block isLoading={loading} onClick={() => sendVerificationEmailMutation()}>Send Verification Email</Button>
          </div>
        </div>
      }
    </div>
  )
}

export default VerifyEmail