import classNames from 'classnames'
import { ReactNode } from 'react'

type AlignmentTypes = 'left' | 'center' | 'right'

type HeadingProps = {
  children: ReactNode
  align?: AlignmentTypes,
  className?: string
}

const alignment = (align: AlignmentTypes) => {
  return classNames({
    'text-left': align === 'left',
    'text-center': align === 'center',
    'text-right': align === 'right',
  })
}

const Title = ({ children, align = 'left', className = '' }: HeadingProps) => (
  <h1 className={`lg:text-2xl text-xl text-primary-dark font-semibold mt-4 mb-2 ${alignment(align)} ${className}`}>{children}</h1>
)

const Subtitle = ({ children, align = 'left', className = '' }: HeadingProps) => (
  <p className={`text-text-gray-light ${className} ${alignment(align)}`}>{children}</p>
)

const Heading = ({ children, align = 'left', className = '' }: HeadingProps) => (
  <h2 className={`text-2xl text-primary-dark font-semibold mt-2 mb-1 ${alignment(align)} ${className}`}>{children}</h2>
)

export {
  Title,
  Subtitle,
  Heading,
}