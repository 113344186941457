import { useQuery } from '@apollo/client'
import { GET_AUTH_USER } from '@ec/apollo/src/queries/auth'
import { UserType } from '@ec/types'
import useVerified from '@ec/ui/src/hooks/useVerified'
import { ReactNode, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { loginViaSession, setAuthChecked } from './slices/auth'

const App = ({ children }: { children: ReactNode }) => {
  const dispatch = useDispatch()
  
  useVerified()

  const { data, error } = useQuery<{ getAuthUser: UserType }>(GET_AUTH_USER)

  useEffect(() => {
    if (data?.getAuthUser) {
      dispatch(loginViaSession(data.getAuthUser))
    }
    if (data || error) {
      dispatch(setAuthChecked(true))
    }
  }, [data, error])

  return (
    <div className="h-full App mx-auto">
      {children}
    </div>
  )
}
export default App
