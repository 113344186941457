import { useMutation } from '@apollo/client'
import { Opportunity, Report } from '@ec/types'
import { Button, Modal, Select, Toast } from '@ec/ui'
import { useEffect } from 'react'
import { useNotifier } from 'react-headless-notifier'
import { SubmitHandler, useForm } from 'react-hook-form'
import { CREATE_REPORT } from '@ec/apollo/src/mutations/report'
import { ReportReason } from 'helpers/enums/ReportReason'
import { Reportable } from 'helpers/enums/Reportable'

type ReportForm = {
  reportable_id?: string
  reportable_type?: string
  reason: ReportReason
}

type PropTypes = {
  opportunity?: Opportunity
  isOpen: boolean
  onClose: () => void
}

const ReportOpportunityModal = ({
  opportunity,
  isOpen = false,
  onClose,
}: PropTypes) => {
  const { notify } = useNotifier()

  const [reportMutation, { 
    data: reportData, 
    loading: isReportLoading, 
    error: reportError,
  }] = useMutation<{ createReport: Report }>(CREATE_REPORT)

  const { register, handleSubmit, setValue } = useForm<ReportForm>({
    defaultValues: {
      reason: ReportReason.INAPPROPRIATE_CONTENT,
      reportable_type: Reportable.OPPORTUNITY,
    },
  })

  const onReportSubmit: SubmitHandler<ReportForm> = (form) => {
    reportMutation({
      variables: {
        input: {
          reportable_id: form.reportable_id,
          reportable_type: form.reportable_type,
          reason: form.reason,
        },
      },
    })
  }

  useEffect(() => {
    if (opportunity) {
      setValue('reportable_id', opportunity.id)
    }
  }, [opportunity])

  useEffect(() => {
    if (reportData && !isReportLoading) {
      notify(<Toast title="Report Sent" message="Thank you for reporting this issue to us" />)
      onClose()
    }

    if (reportError) {
      notify(<Toast title="Report Not Sent" message="Something went wrong while trying to send your report" type="error" />)
      onClose()
    }
  }, [reportData])

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='medium'>
      <form onSubmit={handleSubmit(onReportSubmit)}>
        <Modal.Header title="Report Opportunity" />
        <Modal.Content className='min-h-[200px]'>
          <Select
            {...register('reason')}
            label="What is wrong with this opportunity?"
            values={[
              { id: '1', value: ReportReason.INAPPROPRIATE_CONTENT, label: 'It contains inappropriate content' },
              { id: '2', value: ReportReason.INCORRECT_CONTENT, label: 'It spreads false information' },
              { id: '3', value: ReportReason.FRAUDULENT_CONTENT, label: 'It contains fraudulent material' },
              { id: '4', value: ReportReason.OTHER, label: 'Other' },
            ]}
          />
        </Modal.Content>
        <Modal.Footer className="flex justify-end gap-4">
          <Button type="button" onClick={onClose} variant="secondary">Cancel</Button>
          <Button isLoading={isReportLoading}>Report</Button>
        </Modal.Footer>
        <div className="cf-turnstile" data-sitekey={process.env.REACT_APP_CLOUDFLARE_TURNSTILE_SITE_KEY}></div>
      </form>
    </Modal>
  )
}

export default ReportOpportunityModal