import Toast from '../components/Toast'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useNotifier } from 'react-headless-notifier'
import { User } from '@ec/types'

const useVerified = () => {
  const [isVerified, setIsVerified] = useState<boolean>(false)
  const [verificationChecked, setVerificationChecked] = useState<boolean>(false)

  const [searchParams] = useSearchParams()
  const { notify } = useNotifier()

  const checkVerification = (user: User) => {
    if (user.email_verified_at) {
      setIsVerified(true)
    } else {
      setIsVerified(false)
    }

    setVerificationChecked(true)
  }

  useEffect(() => {
    if (searchParams.get('verified')) {
      notify(<Toast type="success" message="Your account has been successfully verified" />)
    }
  }, [])

  return { isVerified, checkVerification, verificationChecked }
}

export default useVerified