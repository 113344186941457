import { useMutation } from '@apollo/client'
import { UPDATE_PASSWORD } from '@ec/apollo/src/mutations/auth'
import { Button, Heading, Input, Toast } from '@ec/ui'
import useErrorHandler from 'hooks/UseErrorHandler'
import { useEffect, useState } from 'react'
import { useNotifier } from 'react-headless-notifier'
import { SubmitHandler, useForm } from 'react-hook-form'

type UpdatePasswordProps = {
  password_confirmation: string
  current_password: string
  new_password: string
}

const AccountDetailsForm = () => {
  const { notify } = useNotifier()
  const errorHandler = useErrorHandler()
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(true)

  const [updatePasswordMutation, { data, loading, error }] = useMutation(UPDATE_PASSWORD)

  const { register, watch, handleSubmit, setError, reset, getValues, formState: { errors } } = useForm<UpdatePasswordProps>({
    defaultValues: {
      password_confirmation: '',
      current_password: '',
      new_password: '',
    },
  })

  const onAccountDetailsSubmission: SubmitHandler<UpdatePasswordProps> = (form) => {
    updatePasswordMutation({
      variables: {
        current_password: form.current_password,
        new_password: form.new_password,
        password_confirmation: form.password_confirmation,
      },
    })
  }

  useEffect(() => {
    if (data) {
      notify(<Toast title="Success" message="Password updated successfully" />)
      reset()
    }
  }, [data])

  useEffect(() => {
    if (error) {
      errorHandler(error, setError)
    }
  }, [error])

  useEffect(() => {
    if ((getValues('new_password') === getValues('password_confirmation')) && isSubmitDisabled === true) {
      if (getValues('new_password') !== '' && getValues('password_confirmation') !== '') {
        setIsSubmitDisabled(false)
      }
    } else {
      if (isSubmitDisabled === false) {
        setIsSubmitDisabled(true)
      }
    }
  }, [watch('new_password'), watch('password_confirmation')] )

  return (
    <div>

      <Heading className="mb-5">Update Account Details</Heading>

      <hr className='max-w-[900px]'/>

      <form onSubmit={handleSubmit(onAccountDetailsSubmission)}>

        <div className="mt-8 max-w-[900px] flex flex-col">

          <div className="flex flex-col gap-8">
            <div className="flex lg:flex-row flex-col lg:justify-between lg:items-center">
              <p className="text-text-gray">Current Password</p>
              <Input 
                {...register('current_password')} 
                type="password" 
                width="max-w-md" 
                error={errors.current_password?.message} 
              />
            </div>

            <div className="flex lg:flex-row flex-col lg:justify-between lg:items-center">
              <p className="text-text-gray">New Password</p>
              <Input 
                {...register('new_password')} 
                type="password" 
                width="max-w-md" 
                error={errors.new_password?.message} 
              />
            </div>

            <div className="flex lg:flex-row flex-col lg:justify-between lg:items-center">
              <p className="text-text-gray">Confirm New Password</p>
              <Input 
                {...register('password_confirmation')} 
                type="password" 
                width="max-w-md" 
                error={errors.password_confirmation?.message} 
              />
            </div>
          </div>

          <div className="ml-auto lg:mt-6 mt-10">
            <Button 
              disabled={isSubmitDisabled}
              isLoading={loading}
            >
              Update Password
            </Button>
          </div>

        </div>

      </form>

    </div>
  )
}

export default AccountDetailsForm