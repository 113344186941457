import { useMutation } from '@apollo/client'
import { UPDATE_NOTIFICATION_PREFERENCES } from '@ec/apollo/src/mutations/user'
import { Button, Checkbox, Toast } from '@ec/ui'
import React, { useEffect } from 'react'
import { useNotifier } from 'react-headless-notifier'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { RootState } from 'store'

type NotificationPreferencesForm = {
  leave_opportunity: boolean
  submission_accepted: boolean
  submission_pending: boolean
  submission_rejected: boolean
  upcoming_opportunities: boolean
  opportunity_cancelled: boolean
  opportunity_feedback: boolean
  opportunity_postponed: boolean
}

const AccountNotificationsPage = () => {
  const { register, handleSubmit, setValue } = useForm<NotificationPreferencesForm>()
  const { notify } = useNotifier()
  const { user } = useSelector((state: RootState) => ({
    user: state.auth.user,
  }))

  useEffect(() => {
    setValue('leave_opportunity', user?.notificationPreferences.leave_opportunity ?? false)
    setValue('submission_accepted', user?.notificationPreferences.submission_accepted ?? false)
    setValue('submission_pending', user?.notificationPreferences.submission_pending ?? false)
    setValue('submission_rejected', user?.notificationPreferences.submission_rejected ?? false)
    setValue('upcoming_opportunities', user?.notificationPreferences.upcoming_opportunities ?? false)
    setValue('opportunity_cancelled', user?.notificationPreferences.opportunity_cancelled ?? false)
    setValue('opportunity_feedback', user?.notificationPreferences.opportunity_feedback ?? false)
    setValue('opportunity_postponed', user?.notificationPreferences.opportunity_postponed ?? false)
  }, [])

  const notificationCategoryClassNames = 'flex flex-col gap-4'
  const [updateNotificationsPreferenceMutation, { data, loading: isLoading, error: isError }] = useMutation(UPDATE_NOTIFICATION_PREFERENCES)

  const onSubmit: SubmitHandler<NotificationPreferencesForm> = (form) => {
    updateNotificationsPreferenceMutation({
      variables: { 
        input: {
          leave_opportunity: form.leave_opportunity,
          submission_accepted: form.submission_accepted,
          submission_pending: form.submission_pending,
          submission_rejected: form.submission_rejected,
          upcoming_opportunities: form.upcoming_opportunities,
          opportunity_cancelled: form.opportunity_cancelled,
          opportunity_feedback: form.opportunity_feedback,
          opportunity_postponed: form.opportunity_postponed,
        },
      },
    })
  }

  useEffect(() => {
    if (isError){
      notify(<Toast type='error' message='There was a problem updating your preferences. Please try again later.' title='Preferences Not Updated'/>)
    }
    
    if (!isLoading && data && !isError) {
      notify(<Toast type='success' message='Your notification preferences have successfully been updated' title='Preferences Updated'/>)
    }
  }, [isLoading, data, isError])

  return (
    <>
      <h1 className='text-2xl font-bold'>Notifications</h1>
      <hr className='w-full mt-4 mb-8'/>

      <form className='flex flex-col gap-4' onSubmit={handleSubmit(onSubmit)}>
        <h2 className='text-xl font-semibold'>Opportunity Notifications</h2>
        <div className={notificationCategoryClassNames}>
          <Checkbox variant='alt' {...register('leave_opportunity')} label='Leave opportunities' description='Receive an E-mail notification each time you leave an opportunity you applied for' />
          <Checkbox variant='alt' {...register('opportunity_postponed')} label='Opportunity Postponed' description='Receive an E-mail notification if the date of an opportunity you applied for changes' />
        </div>

        <hr className='w-2/3'/>

        <h2 className='text-xl font-semibold'>Application Notifications</h2>
        <div className={notificationCategoryClassNames}>
          <Checkbox variant='alt' {...register('submission_accepted')} label='Accepted Application(s)' description='Receive an E-mail notification when a volunteer manager accepts your application' />
          <Checkbox variant='alt' {...register('submission_rejected')} label='Declined Application(s)' description='Receive an E-mail notification if a volunteer manager rejects your application' />
          <Checkbox variant='alt' {...register('submission_pending')} label='Pending Application' description='Receive an E-mail notification when an application you made is pending' />
        </div>
        
        <hr className='w-2/3'/>

        <h2 className='text-xl font-semibold'>Other Notifications</h2>
        <div className={notificationCategoryClassNames}>
          <Checkbox variant='alt' {...register('upcoming_opportunities')} label='Upcoming Opportunities' description='Receive an E-mail notification if you have upcoming opportunities' />
          <Checkbox variant='alt' {...register('opportunity_cancelled')} label='Opportunity Cancelled' description='Receive an E-mail notification if an opportunity you applied for has been cancelled' />
          <Checkbox variant='alt' {...register('opportunity_feedback')} label='Opportunity Feedback' description='Receive an E-mail notification reminding you to review an opportunity you completed' />
        </div>

        <div className="flex justify-end lg:mt-6 mt-10">
          <Button isLoading={isLoading}>Save Preferences</Button>
        </div>
      </form>

    </>
  )
}

export default AccountNotificationsPage