type PropTypes = {
  text: string
}

const FormMessage = ({
  text,
}: PropTypes) => {
  return (
    <p className="text-xs text-gray-500 mt-2">{text}</p>
  )
}

export default FormMessage