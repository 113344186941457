import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import Input from '../Input'
import FormError from '../FormError'

type PropTypes = {
  value?: string | undefined
  label?: string | undefined
  error?: string
  onChange?: (date: string) => void
}

const DateInput = ({ label = undefined, value = undefined, error, onChange = () => { } }: PropTypes) => {

  const [day, setDay] = useState('')
  const [month, setMonth] = useState('')
  const [year, setYear] = useState('')

  const [errorMessage, setErrorMessage] = useState(error)

  useEffect(() => {
    if (day !== '' && month !== '' && year !== '') {

      const date = `${year}-${month}-${day}`

      if (dayjs(date).isValid()) {
        onChange(date)

        setErrorMessage('')
      } else {
        setErrorMessage('Invalid date provided')
      }
    }
  }, [day, month, year])

  useEffect(() => {
    setErrorMessage(error)
  }, [error])

  useEffect(() => {
    if(value && dayjs(value).isValid()) {
      
      const date = dayjs(value)
      setDay(date.get('date').toString().padStart(2, '0'))
      setMonth((date.get('month') + 1).toString().padStart(2, '0'))
      setYear(date.get('year').toString())
    }
  }, [value])

  return (
    <div>
      {
        label &&
        <label className="block text-sm font-medium text-gray-700">
          {label}
        </label>
      }
      <div className="flex gap-4">
        <Input
          placeholder="Day"
          required
          inputMode="numeric"
          value={day}
          onChange={(e) => setDay(e.target.value)}
          maxLength={2}
        />

        <Input
          placeholder="Month"
          required
          inputMode="numeric"
          value={month}
          onChange={(e) => setMonth(e.target.value)}
          maxLength={2}
        />

        <Input
          placeholder="Year"
          required
          inputMode="numeric"
          value={year}
          onChange={(e) => setYear(e.target.value)}
          maxLength={4}
        />
      </div>
      <div>
        {
          errorMessage &&
          <FormError text={errorMessage} />
        }
      </div>
    </div>
  )
}

export default DateInput