import { Listbox } from '@headlessui/react'
import { ChangeEvent, useMemo, useState } from 'react'
import { forwardRef } from 'react'
import { ChevronDownIcon } from '@heroicons/react/24/solid'

type ValueProps = {
  id: string
  value: string | null | undefined
  label: string
}

type PropTypes = {
  label?: string
  name?: string
  defaultValue?: string
  onChange?: (e: ChangeEvent<HTMLSelectElement>) => void
  onBlur?: (e: ChangeEvent<HTMLSelectElement>) => void
  disabled?: boolean
  values: ValueProps[]
  labelText?: string
}

const Select = forwardRef<HTMLSelectElement, PropTypes>(function Select({
  values,
  onChange,
  disabled = false,
  labelText,
  defaultValue,
}, ref) {

  const selectHandler = (selection) => {
    setSelectedValue(selection)
    if (onChange) {
      onChange(selection)
    }
  }

  const [selectedValue, setSelectedValue] = useState<ValueProps>({ id: '0', label: labelText ?? 'Select a value', value: undefined })

  // Set to default value if present
  useMemo(() => {
    if (defaultValue && values) {
      let value = values[values.findIndex((element) => element.value === defaultValue)]
      selectHandler(value)
    }
  }, [defaultValue, values])

  return (
    <div className="w-full relative">

      <Listbox value={selectedValue} onChange={selectHandler} disabled={disabled}>

        <Listbox.Button className="font-semibold flex gap-2 border py-3 px-[26px] rounded-lg w-full">
          {selectedValue.label}
          <ChevronDownIcon className="h-5 my-auto ml-auto" />
        </Listbox.Button>

        {
          values && values.length > 0 &&
          <Listbox.Options className="absolute w-full mt-2 border py-2 rounded-lg bg-white z-10 cursor-pointer">
            {
              values.map((value) => (
                /* Use the `active` state to conditionally style the active option. */
                /* Use the `selected` state to conditionally style the selected option. */
                <Listbox.Option key={value.id} value={value}>
                  {({ active, selected }) => (
                    <div
                      className={`
                        ${active && !selected && 'bg-light-gray text-black'}
                        ${selected && 'bg-primary-blue text-white'}
                        ${selected && 'bg-primary-blue text-white'}
                        py-2 px-4`
                      }
                    >
                      {value.label}
                    </div>
                  )}
                </Listbox.Option>
              ))}
          </Listbox.Options>
        }

      </Listbox>

    </div>

  )
})

export default Select