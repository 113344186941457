const CategoryIcon = ({ className }: { className: string }) => {
  return (
    <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path d="M6.71463 1H2.95766C2.43846 1 1.94052 1.20625 1.57339 1.57339C1.20625 1.94052 1 2.43846 1 2.95766V6.71463C1 7.23406 1.20621 7.73261 1.57338 8.09891L9.90953 16.4351C10.5177 17.0432 11.4583 17.1938 12.1778 16.7222C13.9889 15.536 15.536 13.9889 16.7222 12.1778C17.1938 11.4583 17.0432 10.5177 16.4351 9.90953L8.09978 1.57425C7.91796 1.3922 7.70204 1.24778 7.46437 1.14925C7.22669 1.05071 6.97192 0.999998 6.71463 1Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M3.61021 3.61021H3.61717V3.61717H3.61021V3.61021Z" stroke="#7D7D7D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default CategoryIcon
