import { Container, Title } from '@ec/ui'
import { Link, Outlet, useLocation } from 'react-router-dom'

const OpportunitiesOutlet = () => {
  const location: string = useLocation().pathname
  const outletPaths: { title: string, url: string }[] = [
    { title: 'All Opportunities', url: '/dashboard/opportunities' },
    { title: 'Upcoming Opportunities', url: '/dashboard/opportunities/upcoming' },
    { title: 'Pending Opportunities', url: '/dashboard/opportunities/pending' },
    { title: 'Past Opportunities', url: '/dashboard/opportunities/past' },
  ]

  return (
    <>

      <Container>

        <Title className="text-">Your Opportunities</Title>

        <div className="border-b divider flex flex-wrap gap-10 mt-10">
          {
            outletPaths.map((path, index) => (
              <Link key={index} to={path.url} className={`pb-5 text-sm lg:text-base ${location === path.url ? 'border-b-primary-pink border-b-2 text-black' : 'text-dark-gray'}`}>{path.title}</Link>
            ))
          }
        </div>

      </Container>

      <Outlet />

    </>
  )
}

export default OpportunitiesOutlet