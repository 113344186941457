import { useMutation } from '@apollo/client'
import { FORGOT_PASSWORD, RESET_PASSWORD } from '@ec/apollo/src/mutations/auth'
import { Button, Input, Toast } from '@ec/ui'
import { useEffect, useMemo, useState } from 'react'
import { useNotifier } from 'react-headless-notifier'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { login } from 'slices/auth'
import dayjs from 'dayjs'

type ActivateAccountProps = {
  email: string
  name: string
  token: string | null
  password: string
  password_confirmation: string
}

type ForgotPasswordProps = {
  email: string
}

const ResetPasswordPage = () => {
  const search = useLocation().search
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const preDeterminedEmail = searchParams.get('email')
  const preDeterminedName = searchParams.get('name')
  const [expired, setExpired] = useState<Boolean>(false)
  const { notify } = useNotifier()
  const [forgotPasswordSent, setForgotPasswordSent] = useState<boolean>(false)

  const token = useMemo(() => searchParams.get('token'), [search])

  const { register, handleSubmit, watch, setValue, formState: { errors } } = useForm<ActivateAccountProps>({
    defaultValues: {
      password: '',
      password_confirmation: '',
      token: token,
    },
  })
  
  const doPasswordsMatch = watch('password_confirmation') === watch('password')
  
  const [resetPasswordMutation, { data: resetData, error: isResetError, loading: isResetLoading }] = useMutation(RESET_PASSWORD)
  const [forgotPasswordMutation, { loading, data, error }] = useMutation(FORGOT_PASSWORD)

  const onSubmit: SubmitHandler<ActivateAccountProps> = (form) => {
    resetPasswordMutation({ 
      variables: { 
        email: form.email,
        password: form.password,
        passwordConfirmation: form.password_confirmation,
        token: form.token,
      }, 
    })
  }

  const sendForgotPassword: SubmitHandler<ForgotPasswordProps> = (form) => {
    forgotPasswordMutation({
      variables: {
        email: form.email,
      },
    })
  }

  useEffect(() => {
    if (data) {
      setForgotPasswordSent(true)
    }
  }, [data])

  useEffect(() => {
    if (resetData?.resetPassword) {
      dispatch(login(resetData?.resetPassword))
      notify(<Toast type='success' message='Your account has successfully been activated. Please log in again to continue.' />)
      navigate('/login')
    }
  }, [resetData])

  useEffect(() => {
    if (isResetError) {
      notify(<Toast type='error' message='We are unable to activate your account at this time, please try again later' />)
    }
  }, [isResetError])

  useEffect(() => {
    if (error) {
      notify(<Toast title="Error" message="Something went wrong. Please try again later" type="error" />)
    }
  }, [error])

  useEffect(() => {
    if (preDeterminedEmail) {
      setValue('email', preDeterminedEmail)
    }
    let expireTime = searchParams.get('expiry')
    if (expireTime) {
      setExpired(dayjs().isAfter(dayjs.unix(parseInt(expireTime))))
    }
  }, [])

  return (
    <>
      <div className="flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="mt-6 text-3xl font-bold tracking-tight text-center text-gray-900">
            {
              expired ? 
                forgotPasswordSent ?
                  'Activation email sent' : 
                  'This link has expired' : 
                'Activate your account'
            }
          </h2>
          <p className="mx-auto mt-3 text-sm text-center text-gray-500">
            {
              expired ? 
                forgotPasswordSent ?
                  'If you are activating with us, an email has been sent to your account with the next steps to reset your password' : 
                  'Please request a new link to activate your account' : 
                'Confirm your email, name and new password to activate your account'
            }
          </p>
        </div>
 
        
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="px-4 py-8 bg-white sm:rounded-lg sm:px-10">
            { expired ?
              !forgotPasswordSent &&
                <Button 
                  block
                  isLoading={loading}
                  onClick={handleSubmit(sendForgotPassword)}
                >
                  Request new link
                </Button>
              :
              <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
                <Input
                  label="Email"
                  value={preDeterminedEmail ?? undefined}
                  disabled={preDeterminedEmail ? true : false} 
                  required
                  {...register('email')}
                  expanding
                />

                <Input
                  label="Name"
                  value={preDeterminedName ?? undefined}
                  disabled={preDeterminedName ? true : false} 
                  required
                  {...register('name')}
                />

                <Input 
                  label="Password"
                  type="password"
                  required
                  error={errors.password?.message}
                  {...register('password', {
                    required: {value: true, message: 'This field is required'},
                    minLength: {value: 8, message: 'Passwords must contain at least 8 characters'},
                  })}
                />
              
                <div>
                  <Input 
                    label="Confirm Password"
                    type="password"
                    required
                    error={errors.password_confirmation?.message}
                    {...register('password_confirmation', {
                      required: {value: true, message: 'This field is required'},
                    })}
                  />
                  { 
                    (watch('password').length > 1 && watch('password_confirmation').length > 1) 
                      ? doPasswordsMatch
                        ? <p className="text-sm text-green-600">These passwords match</p>
                        : <p className='text-sm text-red-600'>These passwords do not match</p>
                      : <></>
                  }
                </div>

                <Button 
                  block
                  isLoading={isResetLoading}
                  disabled={!doPasswordsMatch}
                >
                Activate Account
                </Button>
              
                <div className="cf-turnstile" data-sitekey={process.env.REACT_APP_CLOUDFLARE_TURNSTILE_SITE_KEY}></div>
              </form>
            }
          </div>
        </div>

      </div>
      
    </>
  )
}

export default ResetPasswordPage