import FormError from '../../components/FormError'
import { ChangeEvent, FocusEventHandler, forwardRef } from 'react'
import { Heading } from '../../components/Headings'

type PropTypes = {
  label: string
  name?: string
  onBlur?: FocusEventHandler
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void
  placeholder?: string
  required?: boolean
  className?: string
  error?: string
}

const TextArea = forwardRef<HTMLTextAreaElement, PropTypes>(function Input({
  label,
  name,
  className = '',
  onBlur,
  onChange,
  placeholder,
  required = false,
  error,
}, ref) {
  return (
    <div className={`w-full ${className}`}>
      <div className="relative">
        <Heading>{label}</Heading>
        {
          error &&
          <div>
            <FormError text={error} />
          </div>
        }
      </div>
      <hr className="bg-divider mb-2.5 mt-5" />
      <textarea
        ref={ref}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        placeholder={placeholder}
        required={required}
        className="text-text-gray text-sm w-full h-64 p-8 focus:outline-primary-green" 
      />
    </div>
  )
})

export default TextArea