import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import DateFormat from '../../../enums/DateFormat'
import Skeleton from 'react-loading-skeleton'
import CalendarIcon from '../../../icons/calendarIcon'
import ClockIcon from '../../../icons/clockIcon'

type PropTypes = {
  altText?: string
  isoDate?: string,
  subtitle?: string,
  isLoading?: boolean,
  isDeadline?: boolean,
}

const DateBanner = ({
  altText = 'No Date',
  isoDate,
  subtitle,
  isLoading = false,
  isDeadline = false,
}: PropTypes) => {
  dayjs.extend(advancedFormat)

  return (
    <div className="w-full border border-divider p-2.5 lg:p-5 rounded-[10px]">
      <div className="flex w-full items-center">

        {
          isLoading
            ? (
              <Skeleton height={36} width={40} />
            ) : (
              <div className="bg-muted rounded-md p-[0.6875rem]">
                {
                  !isDeadline
                    ? <CalendarIcon className="w-[1.125rem] h-[1.125rem]" />
                    : <ClockIcon className="w-[1.125rem] h-[1.125rem]" />
                }
              </div>
            )
        }

        <div className="w-full flex flex-wrap items-center overflow-x-auto">
          {
            isLoading
              ? (
                <div className="w-full max-w-[18.75rem] ml-[1.25rem]">
                  <Skeleton  height={28} className="max-w-[8.125rem] lg:max-w-[18.75rem] my-auto" />
                </div>  
              ) : isoDate
                ? (
                  <>
                    <p className="hidden lg:block font-semibold text-lg mr-[0.9375rem] ml-[1.25rem]">{dayjs(isoDate).format(DateFormat.FullDate)}</p>
                    <p className="block lg:hidden font-semibold text-sm mr-[0.9375rem] ml-[1.25rem]">{dayjs(isoDate).format(DateFormat.ShortDate)}</p>
                  </>
                ) : (
                  <p className="lg:block font-semibold text-lg mr-[0.9375rem] ml-[1.25rem]">{altText}</p>
                )
          }

          {
            (subtitle && !isLoading) &&
            <p className="text-gray lg:text-sm text-xs ml-4">{subtitle}</p>
          }
        </div>


      </div>
    </div>
  )
}

export default DateBanner