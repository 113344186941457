import { useQuery } from '@apollo/client'
import { User } from '@ec/types'
import { GET_USER_OPPORTUNITIES } from '@ec/apollo/src/queries/auth'
import { Button, Container } from '@ec/ui'
import { TimePeriod } from 'helpers/enums/TimePeriod'
import { Link, Outlet, useNavigate } from 'react-router-dom'
import OpportunityDashboardCard from 'components/OpportunityDashboardCard'
import OpportunityDashboardCardSkeleton from 'components/Skeletons/OpportunityDashboardCardSkeleton'

const Dashboard = () => {
  const navigate = useNavigate()
  const { data, loading: isLoading} = useQuery<{ getAuthUser: User }>(GET_USER_OPPORTUNITIES, {
    variables: {
      opportunitiesFirst: 5,
      opportunitiesFilter: {
        timePeriod: TimePeriod.UPCOMING,
      },
    },
  })


  return (
    <>
      <Container>

        <div className="bg-off-green py-10 px-[1.875rem] lg:px-[3.75rem] rounded-xl mb-[2.875rem] overflow-x-auto">

          <h1 className="text-2xl lg:text-3xl font-bold mb-4 text-primary-dark">
            We have found new opportunities in your area
          </h1>

          <p className="text-base lg:text-lg font-normal text-primary-dark mb-[1.875rem]">
            Discover the latest opportunities recommended for you
          </p>

          <Link to="/">
            <Button variant="success">
              Explore new opportunities
            </Button>
          </Link>

        </div>

        <div className="flex flex-wrap lg:flex-row lg:items-center lg:justify-between mb-4 max-w-[56.25rem] w-full gap-y-3">

          <div className="flex flex-wrap items-center gap-[0.625rem]">
            <h1 className="text-2xl font-bold">
              {
                data?.getAuthUser.opportunities.paginatorInfo?.total
              }
              {' '}
              {
                data?.getAuthUser.opportunities.paginatorInfo?.total === 1
                  ? 'opportunity'
                  : 'opportunities'
              }
            </h1>
            <p className="text-lg text-text-gray-light">
              Upcoming Soon
            </p>
          </div>

          <Button variant="primary-gray" onClick={() => navigate('/opportunities')}>
            View all
          </Button>

        </div>

        <hr className="max-w-[56.25rem] w-full"/>

        <div className="mt-8 space-y-8 mb-[1.25rem]">
          {
            isLoading &&
            <>
              <OpportunityDashboardCardSkeleton />
              <OpportunityDashboardCardSkeleton />
              <OpportunityDashboardCardSkeleton />
            </>
          }
          {
            data?.getAuthUser?.opportunities?.data && data?.getAuthUser?.opportunities.data.length > 0
              ? data.getAuthUser?.opportunities.data.map((opportunity) => {
                return (
                  <OpportunityDashboardCard key={`upcoming-opportunities-${opportunity.id}`} opportunity={opportunity} />
                )
              })
              : <p className="text-text-gray text-sm">You have no upcoming events at the moment, <Link to="/" className="text-primary-blue hover:underline">explore new opportunities!</Link></p>
          }
        </div>

        {
          data?.getAuthUser?.opportunities?.paginatorInfo?.total ?? 0 > 5 
            ? (
              <div className="flex justify-center mb-4 mt-[3.125rem]">
                <Link to="/dashboard/opportunities">
                  <Button variant="primary-gray">
                  View All Opportunities
                  </Button>
                </Link>
              </div>
            ) : null
        }

      </Container>
      <Outlet />

    </>
  )
}

export default Dashboard