import { useMutation } from '@apollo/client'
import { LOGIN_VOLUNTEER } from '@ec/apollo/src/mutations/auth'
import { LoginResponseType } from '@ec/types'
import { Button, Checkbox, Input } from '@ec/ui'
import useErrorHandler from 'hooks/UseErrorHandler'
import { useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { login } from 'slices/auth'

type LoginProps = {
  email: string
  password: string
  remember_me: boolean
}

const LoginPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const errorHandler = useErrorHandler()

  const { register, handleSubmit, formState: { errors }, setError } = useForm<LoginProps>({
    defaultValues: {
      remember_me: false,
    },
  })

  const [loginMutation, { data: loginData, loading: isLoginLoading, error: loginError }] = useMutation<{ loginVolunteer: LoginResponseType }>(LOGIN_VOLUNTEER)

  const onSubmit: SubmitHandler<LoginProps> = (form) => {
    loginMutation({ 
      variables: { 
        input: {
          email: form.email, 
          password: form.password, 
        },
      }, 
    })
  }

  useEffect(() => {
    if (loginData?.loginVolunteer) {
      if(loginData.loginVolunteer.user !== null && loginData.loginVolunteer.two_factor_challenged === false) {
        dispatch(login(loginData.loginVolunteer))

        if (searchParams.get('transfer')) {
          navigate(`/opportunities/${searchParams.get('transfer')}`)
        } else {
          navigate('/')
        }
      } 
      else if (loginData.loginVolunteer.user === null && loginData.loginVolunteer.two_factor_challenged === true) {
        navigate('/login/challenge')
      }
    }
  }, [loginData])

  useEffect(() => {
    if (loginError) {
      errorHandler(loginError, setError)
    }
  }, [loginError])


  return (
    <>
      <div className="flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">Sign in to your account</h2>
        </div>
 
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 sm:rounded-lg sm:px-10">

            <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
              <Input 
                label="Email"
                {...register('email')}
                type="email" 
                required
                error={errors.email?.message}
                expanding
              />

              <Input 
                label="Password"
                {...register('password')} 
                type="password" 
                required
                error={errors.password?.message}
              />

              <Checkbox label="Remember me" {...register('remember_me')} />
              
              <Button block isLoading={isLoginLoading}>
                Sign in
              </Button>

              <p className="text-sm text-center text-primary-blue">
                <Link to="/forgot-password">Forgotten your password?</Link>
              </p>
              
              <div className="cf-turnstile" data-sitekey={process.env.REACT_APP_CLOUDFLARE_TURNSTILE_SITE_KEY}></div> 
            </form>
            
          </div>
        </div>

      </div>
    </>
  )
}

export default LoginPage