import { Opportunity } from '@ec/types'
import { Image } from '@ec/ui'
import { Popover } from '@headlessui/react'
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import { Link } from 'react-router-dom'

type PropTypes = {
  opportunity: Opportunity
  lat: number,
  lng: number
}

const MapMarker = ({ opportunity }: PropTypes) => {
  const addressLine = [opportunity.city, opportunity.country].join(', ')

  return (
    <>
      <Popover className="relative">

        <Popover.Panel className="absolute z-10 bottom-14 rounded-lg overflow-hidden">

          <div className="bg-white max-w-[18.375rem] h-[15.8125rem] border border-secondary-blue rounded-lg overflow-hidden">

            <Link to={`/opportunities/${opportunity?.slug}`}>
              <div className="h-[11.25rem] w-auto overflow-clip">
                <Image source={opportunity.images[0].url} className="object-cover h-full w-full overflow-hidden" />
              </div>

              <div className="px-3 pt-3 space-y-1">
                <div className="flex items-center">
                  <h3 className="font-semibold leading-5 text-lg truncate">
                    {opportunity.title}
                  </h3>
                  <ChevronRightIcon className='h-6'/>
                </div>
                <p className="leading-5 text-base mb-5 text-primary-blue truncate">{addressLine}</p>
              </div>
            </Link>

          </div>

        </Popover.Panel>

        <Popover.Button className="rounded-full overflow-hidden border border-secondary-blue">
          {({ open }) => (
            <div className={`transition-colors inline-flex text-sm whitespace-nowrap px-5 py-2 ${open ? 'text-white bg-secondary-blue' : 'bg-white text-secondary-blue'} `}>
              {opportunity.opportunityTypes.length > 0 ? opportunity.opportunityTypes[0].name : 'Opportunity'}
            </div>
          )}
        </Popover.Button>

      </Popover>
    </>
  )
}

export default MapMarker