import { Subtitle } from '../../components/Headings'
import SpinnerIcon from '../../icons/spinnerIcon'
import Logo from '../../svgs/logo'

type LoadingScreenProps = {
  message?: string
}

const LoadingScreen = ({ message }: LoadingScreenProps) => {
  return (
    <div className="h-screen flex items-center justify-center">
      <div className="relative -mt-20 flex flex-col justify-center items-center">
        <Logo className="w-full max-w-[18.75rem] mb-10" />
        <SpinnerIcon className="h-6 w-6" />
        {
          message &&
          <Subtitle className="!mt-10">{message}</Subtitle>
        }
      </div>
    </div>
  )
}

export default LoadingScreen