import Skeleton from 'react-loading-skeleton'

const OpportunityDashboardCardSkeleton = () => {
  return (
    <>
      <div className="flex md:flex-row flex-col w-full max-w-[900px]">

        <div className='md:flex gap-2 w-full md:max-w-[264px]'>
          <Skeleton className="w-full pt-[56.25%] h-[190px] md:h-[149px] md:min-w-[264px]" />
        </div>

        <div className="w-full flex gap-3 md:ml-4">
          <div className="flex flex-col w-full">
            <Skeleton height={30} className="mb-1 max-w-[220px] mt-4 md:mt-0" />
            <Skeleton height={28} className="mb-2 max-w-[300px]" />
            <Skeleton height={21} className="mb-4 max-w-[350px]" />
            <Skeleton height={20} width={200} className="mb-4 md:mb-0" />
          </div>
          <div className='hidden md:flex'>
            <Skeleton height={40} width={40} className="" />
          </div>
        </div>

        <div className='block w-full md:hidden'>
          <Skeleton height={46} className="w-full" />
        </div>
      </div>
    </>
  )
}

export default OpportunityDashboardCardSkeleton