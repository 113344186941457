import { gql } from '@apollo/client'
import { USER_FIELDS } from '../fragments'

export const UPDATE_VOLUNTEER = gql`
  ${USER_FIELDS}
  mutation ($input: UpdateVolunteerDetailsInput) {
    updateVolunteerDetails (input: $input) {
      ...UserFields
    }
  }
`

export const UPDATE_NOTIFICATION_PREFERENCES = gql `
  mutation ($input: NotificationPreferencesInput) {
    updateNotificationPreferences (input: $input) {
      id
    }
  }
`