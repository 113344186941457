import { useMutation } from '@apollo/client'
import { UPDATE_VOLUNTEER } from '@ec/apollo/src/mutations/user'
import { UserType } from '@ec/types'
import { Button, Checkbox, ChevronIcon, Container, FormError, FormMessage, Heading, SaveIcon, SpinnerIcon, Textarea, Title, Toast } from '@ec/ui'
import { MAX_FILE_SIZE_MESSAGE } from '@ec/ui/src/config/media-library'
import ProfileHeader from 'components/ProfileHeader'
import { yearsSinceDate } from 'helpers/date-functions'
import useErrorHandler from 'hooks/UseErrorHandler'
import { useEffect, useState } from 'react'
import { useNotifier } from 'react-headless-notifier'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { RootState } from 'store'

type ManageProps = {
  biography: string
  credentials: string
  experience: string
  has_dbs: boolean
  has_drivers_license: boolean
  profile_picture: File
  profile_banner: File
}

type PreviewUrls = {
  profile?: string
  banner?: string
}

const ProfileManagePage = () => {
  const { notify } = useNotifier()
  const errorHandler = useErrorHandler()

  const [previewUrls, setPreviewUrls] = useState<PreviewUrls>({ profile: undefined, banner: undefined })

  const [updateVolunteerDetailsMutation, { data, loading, error }] = useMutation<{ updateVolunteerDetails: UserType }>(UPDATE_VOLUNTEER)

  const { user } = useSelector((state: RootState) => ({
    user: state.auth.user,
  }))

  const { register, handleSubmit, setError, formState: { errors }, control } = useForm<ManageProps>({
    defaultValues: {
      biography: user?.biography,
      credentials: user?.credentials,
      experience: user?.experience,
      has_dbs: user?.has_dbs,
      has_drivers_license: user?.has_drivers_license,
    },
  })

  const onSubmit: SubmitHandler<ManageProps> = (form) => {
    return updateVolunteerDetailsMutation({
      variables: {
        input: {
          biography: form.biography,
          credentials: form.credentials !== '' ? form.credentials : null,
          experience: form.experience !== '' ? form.experience : null,
          has_dbs: form.has_dbs,
          has_drivers_license: form.has_drivers_license,
          profile_picture: form.profile_picture ? form.profile_picture[0] : undefined,
          profile_banner: form.profile_banner ? form.profile_banner[0] : undefined,
        },
      },
    })
  }

  useEffect(() => {
    if (data?.updateVolunteerDetails) {
      notify(<Toast title="Profile Saved" message="Your changes have been saved" />)
      setPreviewUrls({ profile: undefined, banner: undefined })
    }

    if (error) {
      notify(<Toast title="Profile Not Saved" message="There was an issue updating your profile, please try again" type="error" />)
    }
  }, [data, error])

  useEffect(() => {
    if (error) {
      errorHandler(error, setError)
    }
  }, [error])

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>

        <ProfileHeader control={control} user={user} setPreviewUrls={setPreviewUrls} previewUrls={previewUrls} isLoading={!user} />

        {/* Account Information */}
        <div className="lg:mt-8">

          <Container className="relative">

            <div className="absolute mx-8 lg:mx-2 flex items-center right-0 lg:gap-5 gap-1 top-0">

              <Link to={`/profile/${user?.id}`} className="min-w-max text-text-gray flex top-0 right-0 lg:bg-white bg-muted lg:p-3 p-2 rounded-md focus:outline-primary-green outline-offset-4 text-sm">
                <p className="hidden lg:block">Cancel</p>
                <ChevronIcon className="lg:hidden w-4 h-4 rotate-90" />
              </Link>

              <div className="lg:flex hidden">
                <Button isLoading={loading}>
                  Save Changes
                </Button>
              </div>

              <button disabled={loading} className="lg:hidden flex text-white top-0 right-0 bg-primary-green lg:p-3 p-2 rounded-md focus:outline-primary-green outline-offset-4 text-sm">
                {
                  loading
                    ? <SpinnerIcon className="w-4 h-4" />
                    : <SaveIcon className="w-4 h-4" />
                }
              </button>

            </div>

            <div className="lg:mx-[240px]">

              <div className="max-w-[762px]">

                <div className="mb-5">
                  {
                    (errors.profile_picture || errors.profile_banner) ?
                      <FormError text={MAX_FILE_SIZE_MESSAGE} />
                      :
                      <FormMessage text={MAX_FILE_SIZE_MESSAGE} />
                  }
                </div>

                <Link to="/account/settings" className="w-full group">

                  {/* Name and Age */}
                  <div className="flex flex-col lg:flex-row lg:items-center lg:gap-5">
                    <Title className="!my-0 group-hover:underline">{user?.name}</Title>
                    <p className="group-hover:underline text-text-gray-light text-xs">{yearsSinceDate(user?.date_of_birth)} years old</p>
                  </div>

                  {/* Email */}
                  <div className="mt-2.5">
                    <p className="group-hover:underline text-primary-blue lg:text-base text-sm leading-5.5">{user?.email}</p>
                  </div>
                </Link>

                {/* Bio */}
                <Textarea
                  label="Bio"
                  {...register('biography')}
                  placeholder="Write your bio here"
                  className="mt-10"
                  error={errors.biography?.message}
                  required
                />

                {/* Credentials */}
                <Textarea
                  label="Credentials"
                  {...register('credentials')}
                  placeholder="Write your credentials here"
                  className="mt-7"
                  error={errors.credentials?.message}
                />

                {/* Experience */}
                <Textarea
                  label="Experience"
                  {...register('experience')}
                  placeholder="Write your experiences here"
                  className="mt-7"
                  error={errors.experience?.message}
                />

                <div className="w-full mt-7">
                  <Heading>Other Information</Heading>
                  <hr className="bg-divider mb-2.5 mt-5" />
                  <div className="flex flex-col px-8 py-5 gap-3">
                    <Checkbox {...register('has_dbs')} name="dbs" label="Have you had a DBS Check?" />
                    <Checkbox {...register('has_drivers_license')} name="drivers-lic" label="Are you in possession of a drivers license" />
                  </div>
                </div>

              </div>

            </div>

          </Container>

        </div>

      </form>
    </>
  )
}

export default ProfileManagePage