import classNames from 'classnames'
import ChevronIcon from '../../icons/chevronIcon'
import { Link } from 'react-router-dom'

type DirectionTypes = 'left' | 'right'
const rotation = (align: DirectionTypes) => {
  return classNames({
    'rotate-90': align === 'left',
    'rotate-270': align === 'right',
  })
}

type PropTypes = {
  text?: string
  href: string
  direction?: 'left' | 'right'
}

const Breadcrumb = ({
  text,
  href,
  direction = 'left',
}: PropTypes) => {
  return (
    <Link to={href}>
      <div className="flex gap-5 items-center max-w-min group">
        <div className='flex bg-muted w-[40px] h-[40px] rounded-lg'>
          <ChevronIcon className={`w-6 h-6 ${rotation(direction)} m-auto`} />
        </div>
        <p className="group-hover:underline">
          {text}
        </p>
      </div>
    </Link>
  )
}

export default Breadcrumb