import { Opportunity } from '@ec/types'
import { Heading } from '../../../components/Headings'
import Button from '../../../components/Button'
import Skeleton from 'react-loading-skeleton'
import ReactMarkdown from 'react-markdown'
import { FlagIcon } from '../../../icons'
import { Tooltip } from 'react-tooltip'

type PropTypes = {
  isLoading?: boolean
  opportunity?: Opportunity
  onReportClick?: () => void
  handleApply: () => void,
  disabledMessage?: string,
  applyDisabled?: boolean
}

const OpportunityInformation = ({
  isLoading = false,
  opportunity,
  onReportClick,
  handleApply,
  disabledMessage = 'You cannot apply for this opportunity.',
  applyDisabled = false,
}: PropTypes) => {

  return (
    <div className="w-full flex lg:flex-row flex-col gap-8 lg:gap-[100px]">

      {/* body (left) */}
      <div className="w-full lg:max-w-[650px]">
        {
          isLoading
            ? (
              <div className="flex flex-col gap-5">
                <Skeleton className="mt-5" count={3.6} />
                <Skeleton className="mt-5" count={4.8} />
                <Skeleton className="mt-5" count={2.4} />
              </div>
            ) : opportunity &&
            <ReactMarkdown className="prose prose-stone">
              {opportunity.content}
            </ReactMarkdown>
        }
      </div>

      {/* Skills and Application (right) */}
      <div className="sticky top-0 self-start lg:max-w-[450px] min-h-[450px] w-full lg:border-l lg:border-divider lg:px-10 px-5 py-5 ">
        <div className="w-full">
          { disabledMessage && applyDisabled && <Tooltip anchorId={`apply-info-button-${opportunity?.id}`} content={disabledMessage} style={{maxWidth:'20rem'}}/> }
          <Button
            id={`apply-info-button-${opportunity?.id}`}
            onClick={handleApply}
            disabled={applyDisabled}
            block
          >
            Apply Now
          </Button>

          <div className="mt-10">
            <Heading>
              Skills and Credentials
            </Heading>
            {
              isLoading
                ? <>
                  <div className="lg:hidden block">
                    <Skeleton className="mt-5" count={5} />
                  </div>
                  <div className="hidden lg:block">
                    <Skeleton className="mt-5" count={5} width={354} />
                  </div>
                </>
                : opportunity &&
                <ReactMarkdown className="prose prose-stone mt-5">
                  {opportunity.skills}
                </ReactMarkdown>
            }
          </div>

          <div className="max-w-max mx-auto mt-10">
            <div className="flex items-center text-secondary-blue font-medium text-sm">
              <span className="inline">
                <FlagIcon className="w-3.5 h-3.5 mr-1" />
              </span>
              <button type="button" onClick={onReportClick} className='underline' disabled={isLoading}>
                Report opportunity
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OpportunityInformation