import { Opportunity } from '@ec/types'
import { CalendarIcon, CategoryIcon, Image, InfoIcon, OpportunityOptionsModal, Title } from '@ec/ui'
import dayjs from 'dayjs'
import { useState } from 'react'
import { Link } from 'react-router-dom'

type PropTypes = {
  opportunity: Opportunity,
  refetch?: () => void
}

const OpportunityDashboardCard = ({
  opportunity,
  refetch = () => {},
}: PropTypes) => {
  const [isOptionsModalOpen, setIsOptionsModalOpen] = useState<boolean>(false)

  return (
    <>
      <div className="flex md:flex-row flex-col w-full max-w-[900px]">

        <Link to={`/opportunities/${opportunity?.slug}`} className="rounded-lg">
          <div className="md:flex gap-2 w-full md:max-w-[19.3125rem]">
            <Image width={264} height={149} source={opportunity?.images[0].url} className="object-cover flex-grow rounded-lg w-full md:min-w-[16.5rem] md:h-[9.3125rem]" />
          </div>
        </Link>

        {/* Details */}
        <div className="w-full md:ml-4 mt-5 md:mt-0">
          <div className="flex md:flex-row md:justify-between flex-col">

            <div className="w-full max-w-[38.8125rem] order-2 md:order-1">

              {/* Date */}
              <div className="inline-flex items-center gap-[7px] border-[1px] max-w-[300px] border-border-gray rounded-[20px] py-[6px] px-[20px]">
                <CalendarIcon className="text-primary-dark w-6 h-6" />
                <p className="text-sm font-bold">
                  {dayjs(opportunity?.starts_at).format('dddd - D MMMM YYYY')}
                </p>
              </div>

              {/* Title */}
              <Title className="mb-1 lg:mt-[10px] leading-none !text-xl">{opportunity?.title}</Title>
              
              {/* Address */}
              <div className="flex items-center relative mb-[1.1875rem]">
                <p className="md:text-lg text-lg text-primary-blue">{opportunity?.street_address}, {opportunity?.city}, {opportunity?.postcode}</p>
              </div>

              {/* Types */}
              <div className='flex gap-2 items-center'>
                <>
                  <div className="relative ml-1 w-4 h-4 bg-primary-yellow">
                    <CategoryIcon className="mt-[-4px] ml-[-2px] w-4 h-4 text-black" />
                  </div>
                  <p className="text-sm font-medium text-gray">{opportunity.opportunityTypes.map((type) => type.name).join(', ')}</p>
                </>
              </div>
            </div>

          </div>
        </div>
        <div>
          <button
            className="my-auto rounded-md lg:mt-0 mt-6 w-full transition-colors lg:w-10 h-10 bg-muted flex focus:text-primary-green focus:outline-primary-green"
            onClick={() => setIsOptionsModalOpen(true)}
          >
            <InfoIcon className="w-6 h-6 m-auto hidden lg:flex" />
            <p className="lg:hidden flex m-auto text-text-gray">See More</p>
          </button>
          <button
            onClick={() => setIsOptionsModalOpen(true)}
            className="flex w-full justify-center md:hidden bg-muted p-3 rounded-md mt-4">
              View More
          </button>

        </div>

      </div>

      <OpportunityOptionsModal
        refetch={() => refetch()}
        isOpen={isOptionsModalOpen}
        onClose={() => setIsOptionsModalOpen(false)}
        opportunity={opportunity}
      />
    </>
  )
}

export default OpportunityDashboardCard