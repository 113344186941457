import { useMutation, useQuery } from '@apollo/client'
import { GET_OPPORTUNITY_FORM } from '@ec/apollo/src/queries/opportunities'
import { CREATE_SUBMISSION } from '@ec/apollo/src/mutations/apply'
import { Opportunity } from '@ec/types'
import { Breadcrumb, Button, Container, Image, Input, Toast } from '@ec/ui'
import { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import Skeleton from 'react-loading-skeleton'
import { useNavigate, useParams } from 'react-router-dom'
import { SubmissionResponse } from '@ec/types'
import { useNotifier } from 'react-headless-notifier'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { shortAddress } from '@ec/ui/src/helpers/strings/address'
import Address from '@ec/types/src/address'
import RegisterModal from 'components/RegisterModal'
import useVerified from '@ec/ui/src/hooks/useVerified'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { SEND_VERIFICATION_EMAIL } from '@ec/apollo/src/mutations/auth'
import classNames from 'classnames'

type Answer = {
  questionID: string
  type: string
  answer: string
}

type SubmissionProps = {
  answers: Answer[]
}

const OpportunitiesApply = () => {
  const { slug } = useParams()
  const { notify } = useNotifier()
  const navigate = useNavigate()
  const { isVerified, checkVerification } = useVerified()

  const [isRegisterModalOpen, setIsRegisterModalOpen] = useState(false)
  const [addressDetails, setAddressDetails] = useState<Address>()

  const { loggedInUser, authChecked } = useSelector((state: RootState) => ({
    loggedInUser: state.auth.user,
    authChecked: state.auth.authChecked,
  }))

  const { register, handleSubmit } = useForm<SubmissionProps>()

  const [applyMutation, {
    data: submissionData,
    loading: isSubmissionLoading,
    error: submissionError,
  }] = useMutation<{ apply: SubmissionResponse }>(CREATE_SUBMISSION)

  const [sendVerificationEmailMutation, { data: sendVerificationEmailData, error: sendVerificationEmailError }] = useMutation(SEND_VERIFICATION_EMAIL)

  const {
    data: getOpportunity,
    loading: isOpportunityLoading,
    error: getOpportunityError,
  } = useQuery<{ opportunity: Opportunity }>(GET_OPPORTUNITY_FORM,
    {
      variables: {
        slug: slug,
        filters: {
          is_enabled: true,
        },
      },
    },
  )

  useEffect(() => {
    if (getOpportunityError) {
      navigate('/')
    }
  }, [getOpportunityError])

  useEffect(() => {
    if (submissionError) {
      notify(<Toast
        type="error"
        message={submissionError.message ?? 'There was a problem submitting your application. Please try again later'}
      />)
    }
  }, [submissionError])

  useEffect(() => {
    if (submissionData && !submissionError) {
      navigate('/dashboard/opportunities/pending')
      notify(<Toast type="success" message="You have successfully submit an application for this opportunity" />)
    }
  }, [submissionData])

  useEffect(() => {
    if (loggedInUser && authChecked) {
      checkVerification(loggedInUser)
    }

    if (!loggedInUser && authChecked) {
      setIsRegisterModalOpen(true)
    }
  }, [authChecked])

  useEffect(() => {
    if (sendVerificationEmailError) {
      notify(<Toast type="error" message="We have already sent you a verification email recently. Please check your inbox and try again later." />)
    }
  }, [sendVerificationEmailError])

  useEffect(() => {
    if (getOpportunity) {
      setAddressDetails({
        street_address: getOpportunity.opportunity.street_address,
        postcode: getOpportunity.opportunity.postcode,
        city: getOpportunity.opportunity.city,
      })
    }
  }, [getOpportunity])

  const onSubmit: SubmitHandler<SubmissionProps> = (form) => {
    if (!loggedInUser) {
      setIsRegisterModalOpen(true)
      return
    }

    if (getOpportunity) {
      applyMutation({
        variables: {
          data: JSON.stringify(form.answers),
          opportunity_form_id: parseInt(getOpportunity?.opportunity.form.id),
        },
      })
    }
  }

  return (
    <>
      <Container className="flex flex-col gap-5">

        {
          (loggedInUser && !isVerified) &&
          <div className={classNames('flex flex-col gap-2 p-4  rounded-lg outlin', {
            'bg-yellow-200 outline-yellow-300': !sendVerificationEmailData,
            'bg-blue-100 outline-blue-300': sendVerificationEmailData,
          })}>
            <div className='flex gap-2'>
              <ExclamationTriangleIcon className='h-6' />
              <p className='font-semibold'>
                {sendVerificationEmailData
                  ? 'Verification email sent'
                  : 'Verify your account'
                }
              </p>
            </div>
            {sendVerificationEmailData
              ? <>
                <p>We have sent you a new verification email. Please check your junk mail if you have not received this directly in your inbox.</p>
                <p className='font-semibold'>Once you have verified your email, please refresh this page.</p>
              </>
              : <>
                <p>
                  To continue using your Go Volunteering account, please verify your email address.
                  Please check your account inbox for the verification link, once verified please refresh the page to continue your application.
                </p>
                <span className="text-primary-blue underline cursor-pointer font-semibold mt-2" onClick={() => sendVerificationEmailMutation()}>
                  Click here to send a new verification email link.
                </span>
              </>
            }
          </div>
        }

        <Breadcrumb href={`/opportunities/${slug}`} text="Back" />

        <div className="flex gap-3">


          {
            isOpportunityLoading
              ? <Skeleton width={185} height={104} />
              : <Image className="rounded-xl" width={185} source={getOpportunity?.opportunity.images[0].url} />
          }

          <div className="flex flex-col justify-center">
            {
              isOpportunityLoading
                ?
                <>
                  <Skeleton width={275} height={28} />
                  <Skeleton width={225} height={28} />
                </>
                :
                <>
                  <span className="font-semibold text-xl">{getOpportunity?.opportunity.title}</span>
                  {
                    addressDetails &&
                    <p className="text-primary-blue text-lg">{shortAddress(addressDetails)}</p>
                  }
                </>
            }
          </div>

        </div>

        <div className="flex flex-col gap-1">
          <span className="font-semibold text-2xl">Application Form</span>
          <span className="text-gray">Complete the form to apply for this opportunity</span>
        </div>

        {
          getOpportunity &&
          <form className="flex flex-col space-y-6" onSubmit={handleSubmit(onSubmit)}>
            {
              getOpportunity.opportunity.form.fields.map((field, index) => (
                <>
                  <input
                    key={`question-${field.id}`}
                    {...register(`answers.${index}.questionID`)}
                    value={field.id}
                    type="hidden"
                  />
                  <input
                    key={`questions-${field.type}`}
                    {...register(`answers.${index}.type`)}
                    value={field.type}
                    type="hidden"
                  />
                  <Input
                    key={field.id}
                    {...register(`answers.${index}.answer`)}
                    label={field.title}
                    required={field.is_required}
                    showOptionalLabel={!field.is_required}
                    disabled={!loggedInUser || !isVerified}
                  />
                </>
              ))
            }
            <Button
              isLoading={isSubmissionLoading}
              disabled={!loggedInUser || !isVerified}
            >
              Apply Now
            </Button>
          </form>
        }

      </Container>

      <RegisterModal
        isOpen={isRegisterModalOpen}
        onClose={() => setIsRegisterModalOpen(false)}
        slug={getOpportunity?.opportunity.slug}
      />

    </>
  )
}

export default OpportunitiesApply