import Skeleton from 'react-loading-skeleton'
import FsLightbox from 'fslightbox-react'
import { useState } from 'react'
import { Media } from '@ec/types'
import Image from '../../../components/Image'
import Button from '../../../components/Button'

type PropTypes = {
  images?: Media[] | Partial<Media>[]
  urls?: (string | undefined)[]
  isLoading?: boolean
  onClick?: () => void
}

const OpportunityImages = ({
  images,
  urls,
  isLoading = false,
  onClick,
}: PropTypes) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  return (
    <>
      <div className="flex items-center group focus:outline-none w-full">

        {
          isLoading && (
            <div className="w-full max-w-[50rem]">
              <Skeleton className="max-w-[50rem] h-[12.25rem] lg:h-[28.125rem] rounded-xl" />
            </div>
          )
        }

        {images && images[0] ?
          <div className="relative flex w-full max-w-[50rem] lg:h-[28.125rem] h-[12.1875rem] bg-muted rounded-xl border border-divider">
          
            <div className="absolute top-2 left-2">
              <Button type="button" variant="secondary" onClick={() => setIsOpen(!isOpen)}>View Images</Button>
            </div>
          
            <div className="absolute top-2 right-2 bg-white py-2 px-3 rounded-full border-divider border">
              <p className="text-sm">{images.length} image{images.length > 1 ? 's' : ''}</p>
            </div>
          
            <Image source={images[0].url} className="object-cover rounded-xl" width={1920} height={1080} />
          
          </div>
          : <div className="relative flex w-full max-w-[50rem] h-[4rem] bg-muted rounded-xl border border-divider">
            <p className='m-auto px-4 text-center'>
              When you upload an image, it will appear here.
            </p>
          </div>
        }

        <div className="lg:flex hidden flex-col gap-3 mx-5 max-w-[200px]">
          {
            isLoading
              ? getSkeletonImages()
              : images && images.length > 1 && images.slice(1, 4).map((image, index) => (
                <div key={`images-${index}`} className="border border-divider rounded-lg">
                  <Image source={image.url} width={1280} height={720} className="object-cover overflow-hidden max-w-[12.5rem] max-h-[7rem] rounded-lg aspect-video" />
                </div>
              ))
          }
        </div>

      </div >

      {
        urls &&
        <FsLightbox
          toggler={isOpen}
          sources={urls}
          type="image"
        />
      }
    </>
  )
}

const getSkeletonImages = () => (
  <>
    {
      Array.from(Array(3)).map((item, idx) => (
        <Skeleton key={idx} width={200} height={112} />
      ))
    }
  </>
)

export default OpportunityImages