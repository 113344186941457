import { BrowserRouter, Route, Routes } from 'react-router-dom'
import App from 'App'
import LoginPage from 'pages/auth/loginPage'
import RegisterPage from 'pages/auth/registerPage'
import ProtectedRoute from 'ProtectedRoute'
import ForgotPasswordPage from 'pages/auth/forgotPasswordPage'
import ResetPasswordPage from 'pages/auth/resetPasswordPage'
import ActivateAccountPage from 'pages/auth/activateAccountPage'
import ErrorPage from 'pages/other/errorPage'
import PageLayout from 'pages/layout/main'
import OpportunitiesIndex from 'pages/opportunities'
import OpportunitiesShow from 'pages/opportunities/show'
import TwoFactorChallengePage from 'pages/auth/twoFactorChallengePage'
import AccountAuthenticationPage from 'pages/account/accountAuthenticationPage'
import ConfirmPasswordPage from 'pages/other/confirmPasswordPage'
import AccountPageOutlet from 'pages/layout/account-settings'
import AccountSettingsPage from 'pages/account/accountSettingsPage'
import AccountNotificationsPage from 'pages/account/accountNotificationsPage'
import ProfileOutlet from 'pages/profile/outlet'
import ProfileShowPage from 'pages/profile/show'
import ProfileManagePage from 'pages/profile/manage'
import OpportunitiesOutlet from 'pages/opportunities/outlet'
import PastOpportunitiesPage from 'pages/opportunities/past'
import AllOpportunitiesPage from 'pages/opportunities/all'
import PendingOpportunitiesPage from 'pages/opportunities/pending'
import OpportunitiesApply from 'pages/opportunities/apply'
import Dashboard from 'pages/dashboard'
import UpcomingOpportunitiesPage from 'pages/opportunities/upcoming'
import VerifyEmail from '@ec/ui/src/pages/verifyEmail'
import ScrollToTop from '@ec/ui/src/ScrollToTop'
import UseRestrictionsPolicy from '@ec/ui/src/pages/useRestrictionsPolicy'

const Router = () => {
  return (
    <BrowserRouter>
      <App>
        <ScrollToTop>
          <Routes>

            <Route element={<PageLayout />}>

              <Route path="/" element={<OpportunitiesIndex />} />
              <Route path="/opportunities/:slug" element={<OpportunitiesShow />} />
              <Route path="/opportunities/:slug/apply" element={<OpportunitiesApply />} />

              <Route path="/use-restrictions-policy" element={<UseRestrictionsPolicy />} />

              <Route element={<ProtectedRoute />}>

                <Route path="dashboard" element={<Dashboard />} />

                <Route path="profile" element={<ProfileOutlet />}>
                  <Route path=":id" element={<ProfileShowPage />} />
                  <Route path="manage" element={<ProfileManagePage />} />
                </Route>

                <Route path="account" element={<AccountPageOutlet />}>
                  <Route path="authentication" element={<AccountAuthenticationPage />} />
                  <Route path="settings" element={<AccountSettingsPage />} />
                  <Route path="notifications" element={<AccountNotificationsPage />} />

                </Route>

                <Route path="dashboard/opportunities" element={<OpportunitiesOutlet />}>
                  <Route path="past" element={<PastOpportunitiesPage />} />
                  <Route path="pending" element={<PendingOpportunitiesPage />} />
                  <Route path="upcoming" element={<UpcomingOpportunitiesPage />} />
                  <Route path="" element={<AllOpportunitiesPage />} />
                </Route>

                <Route path="confirm-password" element={<ConfirmPasswordPage />} />
                <Route path="/verify-email" element={<VerifyEmail />} />
              </Route>

            </Route>

            <Route element={<PageLayout />}>
              <Route path="/forgot-password" element={<ForgotPasswordPage />} />
              <Route path="/reset-password" element={<ResetPasswordPage />} />
              <Route path="/activate-account" element={<ActivateAccountPage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/login/challenge" element={<TwoFactorChallengePage />} />
              <Route path="/register" element={<RegisterPage />} />
              <Route path="*" element={<ErrorPage />} />
            </Route>

          </Routes>
        </ScrollToTop>

      </App>
    </BrowserRouter>
  )
}

export default Router