import ReactDOM from 'react-dom'
import {ExclamationTriangleIcon} from '@heroicons/react/24/outline'

type propTypes = {
  title?: string,
  text?: string
}

const HeaderNotification = ({title, text}: propTypes) => {
  // React does *not* create a new div. It renders the children into `domNode`.
  // `domNode` is any valid DOM node, regardless of its location in the DOM.
  return ReactDOM.createPortal(
    <div className='py-4 md:px-8 sm:px-8 bg-off-green'>
      <div className='flex gap-4 lg:max-w-[1200px] w-full mx-auto px-0'>
        <div className='flex gap-2'>
          <ExclamationTriangleIcon className="h-6 w-6 text-secondary-green"/>
          <span className='font-semibold text-dark-gray'>{title}</span>
        </div>
        {text && <span className='text-secondary-green'>{text}</span>}
      </div>
    </div>,
    document.getElementById('banner-notification')!,
  )
}

export default HeaderNotification
