import { useState } from 'react'
import { useEffect } from 'react'

export const useOnScreen = (ref: any) => {
  const [isIntersecting, setIntersecting] = useState(false)
  const [initialLoad, setInitialLoad] = useState(false)

  let observer: any

  useEffect(() => {
    observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && initialLoad === false) {
          setInitialLoad(true)
        }
        setIntersecting(entry.isIntersecting)
      },
    )
  }, [])

  useEffect(() => {
    observer.observe(ref.current)
    return () => { observer.disconnect() }
  }, [])

  return { isIntersecting, initialLoad }
}
