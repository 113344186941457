import Button from '../../Button'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { DateRange, DayPicker, SelectRangeEventHandler } from 'react-day-picker'
import 'react-day-picker/dist/style.css'
import '../styles.DatePicker.css'

export type isoDateRange = {
  from?: string
  to?: string
}

type PropTypes = {
  onChange: (date: isoDateRange) => void
  dates?: isoDateRange
  className?: string
  startBlank?: boolean
}

const RangeDatePicker = ({
  onChange,
  dates,
  className = '',
  startBlank = false,
}: PropTypes) => {
  const defaultState = { from: undefined, to: undefined }

  // For handling expected time format internally
  const [internalRange, setInternalRange] = useState<DateRange | undefined>({
    from: !startBlank ? undefined : dayjs(dates?.from).toDate() ?? undefined,
    to: !startBlank ? undefined : dayjs(dates?.to).toDate() ?? undefined,
  })

  const currentMonth = dayjs(internalRange?.from).toDate()

  // Return ISO format from the component to work with dayjs out the box
  const [isoRange, setIsoRange] = useState<isoDateRange | undefined>({
    from: dates?.from ?? undefined,
    to: dates?.to ?? undefined,
  })

  const handleRangeChange: SelectRangeEventHandler = (dates: DateRange | undefined) => {
    setInternalRange(dates)
    setIsoRange({ from: dayjs(dates?.from).toISOString(), to: dayjs(dates?.to ? dates.to : dates?.from).toISOString() })
  }

  const resetRanges = () => {
    setInternalRange(defaultState)
    setIsoRange(defaultState)
  }

  useEffect(() => {
    if (isoRange) {
      onChange(isoRange)
    }
  }, [isoRange])

  const Footer = () => {
    return (
      <div className="mt-2">
        <Button block variant="secondary" onClick={() => resetRanges()}>
          Remove Selection
        </Button>
      </div>
    )
  }

  return (
    <DayPicker
      className={className}
      mode="range"
      defaultMonth={currentMonth}
      selected={internalRange}
      onSelect={handleRangeChange}
      footer={<Footer />}
    />
  )
}

export default RangeDatePicker