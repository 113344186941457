import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import { useNotifier } from 'react-headless-notifier'
import { useQuery } from '@apollo/client'
import { UserType } from '@ec/types'
import { GET_AUTH_USER } from '@ec/apollo/src/queries/auth'
import { useEffect } from 'react'
import { loginViaSession, logout, setAuthChecked } from 'slices/auth'
import { LoadingScreen, Toast } from '@ec/ui'
import useVerified from '@ec/ui/src/hooks/useVerified'

const ProtectedRoute = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const { notify } = useNotifier()
  const navigate = useNavigate()
  const { isVerified, checkVerification, verificationChecked } = useVerified()

  const { isAuthenticated, authChecked } = useSelector((state: RootState) => ({
    isAuthenticated: state.auth.authenticated,
    authChecked: state.auth.authChecked,
  }))

  const { data, loading, error } = useQuery<{ getAuthUser: UserType }>(GET_AUTH_USER, {
    errorPolicy: 'all',
  })

  useEffect(() => {
    if (data?.getAuthUser) {
      dispatch(loginViaSession(data.getAuthUser))
    } else if (
      (
        (data?.getAuthUser === null && Object.keys(data).includes('getAuthUser'))
        || (((error as { networkError?: { statusCode: Number } })?.networkError?.statusCode ?? 0) === 419)
      )
    ) {
      notify(<Toast type="warning" message="You must be logged in to view this content." />)
      dispatch(logout())
      navigate('/')
    }
    if (data || error) {
      dispatch(setAuthChecked(true))
    }
  }, [data, error])

  useEffect(() => {
    if (authChecked && data?.getAuthUser) {
      checkVerification(data.getAuthUser)
    }

    if (authChecked && data?.getAuthUser) {
      const profileRegex = /^\/profile\/\d+$/

      if (data?.getAuthUser?.context === 'VOLUNTEER_MANAGER' && profileRegex.test(location.pathname)) {
        return
      }
      else if (data?.getAuthUser?.context !== 'VOLUNTEER') {
        notify(<Toast type="warning" message="You are not authorised to see this page!" />)
        navigate('/')
      }
    }
  }, [data, authChecked])

  if (loading || !authChecked || !verificationChecked) {
    return (
      <LoadingScreen />
    )
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} />
  }

  if (location.pathname !== '/verify-email' && !isVerified) {
    return <Navigate to="/verify-email" state={{ from: location }} />
  }

  if (location.pathname === '/verify-email' && isVerified) {
    return <Navigate to="/" />
  }

  return (
    <div className="flex flex-col h-full w-full mx-auto">
      <Outlet />
    </div>
  )
}

export default ProtectedRoute
