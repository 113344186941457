import UserGroupIcon from '../../../icons/userGroupIcon'
import SpinnerIcon from '../../../icons/spinnerIcon'
import Skeleton from 'react-loading-skeleton'
import Image from '../../../components/Image'

type PropTypes = {
  isLoading?: boolean
  organisation?: string
  description?: string
  href?: string,
  logoSrc?: string
}

const OpportunityManagerCard = ({
  organisation,
  description,
  href,
  logoSrc,
  isLoading = false,
}: PropTypes) => {
  return (
    <div className="w-full">
      <div className="flex lg:flex-row flex-col lg:gap-4 lg:items-center mt-7">
        <div className="flex gap-4 items-center">
          {
            isLoading
              ? <SpinnerIcon className="w-[34px] h-[34px]" />
              : logoSrc
                ? <Image source={logoSrc} width={34} height={34} altText="organisation-logo" />
                : <UserGroupIcon className="w-[34px] h-[34px]" />
          }
          {
            isLoading
              ? <Skeleton width={240} />
              : <p className="font-medium">{organisation}</p>
          }
        </div>
        <div>
          {
            isLoading
              ? <Skeleton width={200} />
              : <a href={href} className="text-sm text-primary-blue focus:outline-primary-blue">{href}</a>
          }
        </div>
      </div>
      <div className="mt-5">
        {
          isLoading
            ? <Skeleton count={2.4} />
            : <p className="text-text-gray">{description}</p>
        }
      </div>
    </div>
  )
}

export default OpportunityManagerCard