import { Opportunity, Submission } from '@ec/types'
import DateBanner from '../../../components/Banners/DateBanner'
import DurationBanner from '../../../components/Banners/DurationBanner'
import { Title } from '../../../components/Headings'
import Image from '../../../components/Image'
import { MapPinIcon } from '../../../icons'
import { CategoryIcon } from '@ec/ui'
import { Link } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'
import FsLightbox from 'fslightbox-react'
import { useState } from 'react'
import OpportunityOptionsModal from '../../../components/Modal/OpportunityDetails'
import { InformationCircleIcon } from '@heroicons/react/24/outline'
import { Tooltip } from 'react-tooltip'

type PropTypes = {
  opportunity?: Opportunity
  isLoading?: boolean
  submission?: Submission
  refetch?: () => void
}

const OpportunityDetails = ({
  opportunity,
  isLoading = false,
  submission,
  refetch = () => { },
}: PropTypes) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const imageSources = opportunity?.images?.map((image) => image.url)

  const statusMessages = {
    PENDING: 'Your application for this opportunity is awaiting approval from the manager of the opportunity',
    ACCEPTED: 'Your application for this opportunity has been accepted',
    CANCELLED: 'This opportunity was cancelled by the manager',
    DECLINED: 'The manager of this opportunity has declined your application',
    COMPLETE: 'You have completed this opportunity',
  }

  const [isOptionsModalOpen, setIsOptionsModalOpen] = useState<boolean>(false)
  return (
    <>
      <div key={`opportunities-${opportunity?.id}`} className="flex lg:flex-row flex-col w-full bg-bue-500 max-w-[25rem] lg:max-w-full">

        {/* Image Gallery */}
        <div className="lg:flex gap-2 w-full max-w-[35.75rem]">

          {
            opportunity && opportunity?.images.length >= 4 &&
            <div className="hidden lg:flex flex-col gap-2 max-w-[9.5625rem] w-full">
              <Image height={91} width={153} source={opportunity?.images[1].url} altText="opportunity-image-1" className="rounded-lg h-[5.6875rem] object-cover border border-gray" />
              <Image height={91} width={153} source={opportunity?.images[2].url} altText="opportunity-image-2" className="rounded-lg h-[5.6875rem] object-cover border border-gray" />
              <Image height={91} width={153} source={opportunity?.images[3].url} altText="opportunity-image-3" className="rounded-lg h-[5.6875rem] object-cover border border-gray" />
            </div>
          }

          {
            isLoading &&
            <div className="hidden lg:flex flex-col gap-2 max-w-[9.5625rem] w-full">
              <Skeleton height={91} width={153} className="pt-1" />
              <Skeleton height={91} width={153} className="pt-1" />
              <Skeleton height={91} width={153} className="pt-1" />
            </div>
          }

          <div className="relative flex w-full h-full">

            {
              isLoading
                ? <div className="w-full">
                  <Skeleton className="pt-1 max-w-[25rem] h-[12.3125rem] lg:max-w-[25.6875rem] lg:h-[18.0625rem]" />
                </div>
                :
                <>
                  { submission?.status && 
                    <div
                      id={`status-submission-${opportunity?.id}`}
                      className={`${submission.status ==='ACCEPTED' ? 'bg-primary-yellow ring-yellow-500' : 'bg-white ring-muted'} flex items-center absolute text-sm px-5 py-1.5 left-5 top-5 text-text-gray z-10 rounded-[5px] font-medium ring-2`}
                    >
                      <Tooltip anchorId={`status-submission-${opportunity?.id}`} content={statusMessages[submission.status]} style={{maxWidth:'20rem'}}/>
                      {submission.status}
                    </div>
                  }
                  <Image onClick={() => setIsOpen(true)} width={376} height={289} source={opportunity?.images[0].url} className="object-cover flex-grow rounded-lg max-h-[18.0625rem]" />
                </>
            }
          </div>


        </div>

        {/* Details */}
        <div className="w-full lg:ml-10 mt-5 lg:mt-0">
          <div className="flex lg:flex-row lg:justify-between flex-col">

            <div className='w-full max-w-[38.8125rem]'>
              {
                isLoading
                  ? <div className="w-full">
                    <Skeleton height={16} className="max-w-[12.5rem] lg:max-w-[18.75rem]" />
                  </div>
                  : <Link to={`/opportunities/${opportunity?.slug}`}>
                    <Title className="mb-1 mt-0 leading-none hover:underline inline">{opportunity?.title}</Title>
                  </Link>
              }
              {
                isLoading
                  ? <div className="w-full">
                    <Skeleton height={20} className="max-w-[15rem] lg:max-w-[16.25rem]" />
                  </div>
                  : <div className="ml-[-0.125rem] lg:text-lg text-sm text-primary-blue">
                    <MapPinIcon className="inline w-6 h-6 mr-1" />
                    {[opportunity?.street_address, opportunity?.city, opportunity?.postcode].join(', ')}
                  </div>
              }
              {
                isLoading
                  ? <div className="w-full mt-4">
                    <Skeleton height={16} className="max-w-[10rem]" />
                  </div>
                  : <div className="flex gap-[0.4375rem] items-center mt-4">
                    <>
                      <div className="relative ml-[0.25rem] w-[1rem] h-[1rem] bg-primary-yellow">
                        <CategoryIcon className="mt-[-0.25rem] ml-[-0.125rem] w-4 h-4 text-black" />
                      </div>
                      <p className="text-sm font-medium text-gray">{opportunity?.opportunityTypes.map((type) => type.name).join(', ')}</p>
                    </>
                  </div>
              }
            </div>

            { isLoading
              ? <div className='w-full'>
                <Skeleton height={40} className="flex my-auto ml-auto rounded-md lg:mt-0 mt-6 w-full transition-colors lg:max-w-[40px] max-h-[40px]"/>
              </div>
              : <div className='flex gap-2'>
                <button
                  className="my-auto rounded-md lg:mt-0 mt-6 w-full transition-colors lg:w-10 h-10 bg-muted flex focus:text-primary-green focus:outline-primary-green"
                  onClick={() => setIsOptionsModalOpen(true)}
                >
                  <InformationCircleIcon className="w-6 h-6 m-auto hidden lg:flex" />
                  <p className="lg:hidden flex m-auto text-text-gray">See More</p>
                </button>
              </div>
            }

          </div>

          <div className="mt-6">
            <DateBanner isoDate={opportunity?.starts_at} subtitle="Start Date" isLoading={isLoading} />
          </div>

          <div className="mt-2.5">
            <DurationBanner duration={opportunity?.duration + ' hours' ?? 'Unknown'} isLoading={isLoading} subtitle="Event Duration" />
          </div>

        </div>

      </div>

      <FsLightbox
        toggler={isOpen}
        sources={imageSources}
        type="image"
      />

      <OpportunityOptionsModal
        refetch={() => refetch()}
        isOpen={isOptionsModalOpen}
        onClose={() => setIsOptionsModalOpen(false)}
        opportunity={opportunity}
        submission={submission}
      />
    </>
  )
}

export default OpportunityDetails