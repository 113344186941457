import { useQuery } from '@apollo/client'
import { GET_USER } from '@ec/apollo/src/queries/user'
import { UserType } from '@ec/types'
import { Button, Container, Heading, OpportunitiesGrid, Title } from '@ec/ui'
import { PencilSquareIcon } from '@ec/ui/src/icons'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import ProfileHeader from 'components/ProfileHeader'
import { yearsSinceDate } from 'helpers/date-functions'
import { useOnScreen } from 'hooks/UseOnScreen'
import { useEffect, useRef, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { updateAuthUser } from 'slices/auth'
import { RootState } from 'store'

enum section {
  SHOWCASE = 'SHOWCASE',
  SKILLS = 'SKILLS & CREDENTIALS',
}

const ProfileShowPage = () => {
  const params = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const infiniteScrollRef = useRef(null)
  const { isIntersecting } = useOnScreen(infiniteScrollRef)

  const [selectedSection, setSelectedSection] = useState<string>('SHOWCASE')

  const { loggedInUser } = useSelector((state: RootState) => ({
    loggedInUser: state.auth.user,
  }))

  const { data, loading: isLoading, error, fetchMore } = useQuery<{ getUser: UserType }>(GET_USER, {
    variables: {
      id: params.id,
      opportunitiesPage: 1,
      opportunitiesFirst: 9,
    },
  })

  const sections: section[] = [section.SHOWCASE, section.SKILLS]

  useEffect(() => {
    if (params.id === loggedInUser?.id) {
      // Give any new information to current state
      if (data?.getUser) {
        dispatch(updateAuthUser(data?.getUser))
      }
    }

    if (!data && error) {
      navigate('/404')
    }
  }, [data, error, loggedInUser])

  // Handle infinite scrolling
  useEffect(() => {
    if (selectedSection !== section.SHOWCASE) return
    if (data?.getUser?.opportunities.paginatorInfo?.currentPage === data?.getUser?.opportunities.paginatorInfo?.lastPage) return
    if (isLoading) return

    fetchMore({
      variables: {
        opportunitiesPage: (data?.getUser?.opportunities.paginatorInfo?.currentPage ?? 1) + 1,
        opportunitiesFirst: 9,
      },
    })
  }, [isIntersecting])

  return (
    <>
      { loggedInUser?.context === 'VOLUNTEER_MANAGER' &&
        <Container>
          <div
            className="flex gap-2 p-4 bg-yellow-200 rounded-lg outline outline-yellow-300 mb-6 lg:max-w-[1200px] w-full mx-auto relative max-h-[218px]"
          >
            <div>
              <ExclamationTriangleIcon className='h-6' />
            </div>
            <h1 className='font-semibold'>Viewing as a Volunteer Manager</h1>
            <p>
              You are viewing this profile as a volunteer manager. To go to the volunteer manager portal, <a href={`${process.env.REACT_APP_VOLUNTEER_MANAGER_PORTAL_URL}/volunteers`} className='font-semibold'>click here</a>.
            </p>
          </div>
        </Container>
      }
      
      <ProfileHeader user={data?.getUser} isReadOnly isLoading={isLoading} />

      {/* Account Information */}
      <div className="relative mt-1.5 lg:mt-8">

        <Container className="flex flex-col relative">

          {
            (!isLoading && params.id === loggedInUser?.id) &&
            <div className="inline-flex ml-auto">
              <Button href="/profile/manage">
                <PencilSquareIcon className="w-4 h-4 lg:hidden" />
                <p className="hidden text-center w-full lg:block">Edit</p>
              </Button>
            </div>
          }

          <div className="lg:mx-[15rem]">

            <div className="max-w-[47.625rem]">

              {/* Name and Age */}
              <div className="flex flex-col lg:flex-row lg:items-center lg:gap-5">
                {
                  isLoading
                    ? <Skeleton width={240} height={32} className="pt-1" />
                    : <Title className="!my-0 max-w-[260px]">{data?.getUser.name}</Title>
                }
                {
                  isLoading
                    ? <Skeleton width={90} height={16} />
                    : <p className="text-text-gray-light lg:text-sm text-base">{yearsSinceDate(data?.getUser.date_of_birth)} years old</p>
                }
              </div>

              {/* Email */}
              <div className="mt-2.5">
                {
                  isLoading
                    ? <Skeleton height={20} width={150} className="pt-1" />
                    : <p className="text-primary-blue lg:text-base text-sm leading-5.5">{data?.getUser.email}</p>
                }
              </div>

              {/* Bio */}
              <div className="w-full mt-7">
                {
                  isLoading
                    ? <Skeleton height={12} count={2.75} />
                    : <p className="text-text-gray whitespace-pre-wrap pr-4">{data?.getUser.biography}</p>
                }
              </div>


            </div>

            {/* Profile Section Selection */}
            <div className="max-w-[37.5rem] mx-auto my-[3.75rem]">

              <div className="border-b border-divider flex justify-center gap-8">
                {
                  isLoading
                    ? (
                      <>
                        <Skeleton width={81} height={37} />
                        <Skeleton width={160} height={37} />
                      </>
                    ) : (
                      sections.map((section, index) => (
                        <p
                          key={`section-${index}`}
                          className={`font-medium text-sm pb-4 cursor-pointer ${selectedSection === section ? 'border-b border-primary-pink text-primary-blue' : 'text-placeholder'}`}
                          onClick={() => setSelectedSection(section)}
                        >
                          {section}
                        </p>
                      ))
                    )
                }
              </div>

            </div>

          </div>

        </Container>

        {/* Extra Information and Opportunities Section */}
        {
          selectedSection === section.SHOWCASE
            ? (
              <Container>
                <OpportunitiesGrid keyPrefix='my-profile-opportunities' opportunities={data?.getUser.opportunities?.data} isLoading={isLoading} />
              </Container>
            ) : (
              <Container>
                <div className="max-w-[800px] mx-auto space-y-12">
                  {
                    [data?.getUser.credentials, data?.getUser.experience].every((item) => item === null)
                      ? <p className="text-text-gray text-sm text-center">Looks like this volunteer has not shared their skills and other details just yet, come back later!</p>
                      : <>

                        {
                          data?.getUser.credentials &&
                          <div>
                            <Heading className="border-l-4 border-primary-green pl-4 py-1 !mb-2">Skills & Credentials</Heading>
                            {
                              isLoading
                                ? <Skeleton height={12} count={5.25} />
                                : <p className="whitespace-pre-wrap text-text-gray">{data?.getUser.credentials}</p>
                            }
                          </div>
                        }
                        {
                          data?.getUser.experience &&
                          <div>
                            <Heading className="border-l-4 border-primary-green pl-4 py-1 !mb-2">Experience</Heading>
                            {
                              isLoading
                                ? <Skeleton height={12} count={5.75} />
                                : <p className="whitespace-pre-wrap text-text-gray">{data?.getUser.experience}</p>
                            }
                          </div>
                        }
                      </>
                  }
                </div>
              </Container>
            )
        }

        <div ref={infiniteScrollRef} className="h-[1px] w-full absolute bottom-10 inset-x-0" />

      </div>
    </>
  )
}

export default ProfileShowPage