import { Listbox } from '@headlessui/react'
import classNames from 'classnames'
import CheckIcon from '../../icons/checkIcon'
import { ReactNode, useEffect, useState } from 'react'

export type MultiSelectItem = {
  id: string
  text: string
  unavailable?: boolean
}

type PropTypes = {
  trigger: ReactNode
  triggerClassName?: string
  triggerFocusClassName?: string
  positionClassName: string
  showSelection?: boolean
  onChange?: (ids: string[]) => void
  onOpen?: () => void
  items?: MultiSelectItem[]
  disabled?: boolean
}

const MultiSelect = ({
  trigger,
  triggerClassName = '',
  triggerFocusClassName = '',
  positionClassName = 'left-0',
  items,
  disabled = false,
  onChange,
  onOpen,
  showSelection = false,
}: PropTypes) => {
  const [selectedItems, setSelectedItems] = useState<MultiSelectItem[]>([])

  const addItem = (item: any) => {
    if (selectedItems.map((i) => i.id).includes(item.id)) {
      if (selectedItems.length > 0) {
        const newSelectedItems = [...selectedItems]
        newSelectedItems.splice(newSelectedItems.indexOf(item), 1)
        setSelectedItems(newSelectedItems)
      }
    } else {
      setSelectedItems([...selectedItems, item])
    }
  }

  // Return selected item IDs
  useEffect(() => {
    if (onChange) {
      onChange(selectedItems.map((item) => item.id))
    }
  }, [selectedItems])

  return (
    <div className="flex relative">
      <Listbox value={selectedItems} onChange={addItem}>
        {({ open }) => (
          <>
            <Listbox.Button disabled={disabled} onClick={onOpen} className={`${triggerClassName} ${open ? triggerFocusClassName : ''} ${!items && 'pointer-events-none'}`}>
              {
                showSelection
                  ? selectedItems.length > 0
                    ? selectedItems.map((item) => item.text).join(', ')
                    : trigger
                  : trigger
              }
            </Listbox.Button>
            <Listbox.Options className={`absolute ${positionClassName} z-10 mt-1 max-h-80 max-w-max overflow-y-auto overflow-x-visible rounded-md bg-white py-1 text-base shadow-lg focus:outline-none sm:text-sm border border-divider`}>
              {
                items && items.map((item) => (
                  <Listbox.Option
                    key={item.id}
                    value={item}
                    className={({ active }) =>
                      classNames(
                        active ? 'text-white bg-primary-blue' : 'text-primary-dark',
                        'relative cursor-pointer select-none py-2 pl-3 pr-9',
                      )
                    }
                  >
                    {({ selected, active }) => (
                      <>
                        <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                          {item.text}
                        </span>
                        {
                          selectedItems.map((item) => item.id).includes(item.id) ? (
                            <span
                              className={classNames(
                                active ? 'text-white' : 'text-primary-blue',
                                'absolute inset-y-0 right-0 flex items-center pl-4 pr-1',
                              )}
                            >
                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                          ) : (
                            <></>
                          )
                        }
                      </>
                    )}
                  </Listbox.Option>
                ))
              }
            </Listbox.Options>
          </>
        )}

      </Listbox>
    </div>
  )
}

export default MultiSelect