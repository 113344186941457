import { useQuery } from '@apollo/client'
import { GET_OPPORTUNITY, GET_SIMILAR_OPPORTUNITIES } from '@ec/apollo/src/queries/opportunities'
import { GET_USER_SUBMISSIONS } from '@ec/apollo/src/queries/user'
import { Opportunity, OpportunityQuery, UserType } from '@ec/types'
import { Breadcrumb, Button, Container, DateBanner, FeaturedOpportunitiesSwiper, OpportunitiesTitleBanner, OpportunityImages, OpportunityInformation, OpportunityManagerCard, OpportunityTags, Title } from '@ec/ui'
import HeaderNotification from 'components/HeaderNotifications/HeaderNotification'
import ReportOpportunityModal from 'components/Modals/ReportOpportunityModal'
import RegisterModal from 'components/RegisterModal'
import SocialBar from 'components/SocialBar/SocialBar'
import { TimePeriod } from 'helpers/enums/TimePeriod'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Tooltip } from 'react-tooltip'
import { RootState } from 'store'

const OpportunitiesShow = () => {
  const { slug } = useParams()
  const navigate = useNavigate()

  const [isReportModalOpen, setIsReportModalOpen] = useState<boolean>(false)
  const [isRegisterModalOpen, setIsRegisterModalOpen] = useState(false)
  const [isApplyDisabled, setIsApplyDisabled] = useState<boolean>(false)
  const [applyDisabledMessage, setApplyDisabledMessage] = useState<string | null>(null)
  const [initialLoad, setInitialLoad] = useState(false)

  const { loggedInUser } = useSelector((state: RootState) => ({
    loggedInUser: state.auth.user,
  }))

  const { data: submissionData } = useQuery<{ getAuthUser: UserType }>(GET_USER_SUBMISSIONS, {
    variables: {
      submissionsFirst: 1,
      submissionsPage: 1,
      submissionsFilter: {
        timePeriod: TimePeriod.PENDING_AND_UPCOMING,
        searchQuery: slug,
      },
    },
    notifyOnNetworkStatusChange: true,
  })

  enum OpportunityStatus {
    Published = 'PUBLISHED',
    Draft = 'DRAFT',
    Expired = 'EXPIRED'
  }

  const {
    data: opportunityQuery,
    loading: isOpportunityLoading,
    error,
  } = useQuery<{ opportunity: Opportunity }>(GET_OPPORTUNITY,
    {
      variables: {
        slug: slug,
      },
    },
  )

  const {
    data: similarOpportunitiesQuery,
    loading: isSimilarOpportunitiesLoading,
  } = useQuery<{ similarOpportunities: OpportunityQuery }>(GET_SIMILAR_OPPORTUNITIES,
    {
      variables: {
        id: opportunityQuery?.opportunity.id,
        first: 9,
      },
      notifyOnNetworkStatusChange: true,
      skip: !initialLoad,
    },
  )

  useEffect(() => {
    if (opportunityQuery && (initialLoad === false)) {
      setInitialLoad(true)
    }

    if (opportunityQuery && loggedInUser) {
      if(loggedInUser?.organisation?.id === opportunityQuery?.opportunity.organisation?.id) {
        setIsApplyDisabled(true)
        setApplyDisabledMessage('You cannot apply for an opportunity you manage')
      }

      else if (submissionData){
        setIsApplyDisabled((submissionData.getAuthUser.submissions.data.length >= 1))
        setApplyDisabledMessage('You cannot apply for an opportunity you are already accepted to or have a pending submission on.')
      }

    }
  }, [opportunityQuery, loggedInUser, submissionData])

  const handleApply = () => {
    if (!loggedInUser) {
      setIsRegisterModalOpen(true)
    } else {
      navigate(`/opportunities/${slug}/apply`)
    }
  }

  useEffect(() => {
    if (error) {
      navigate('/404')
    }
  }, [error])

  return (
    <div>
      {
        opportunityQuery?.opportunity.status === OpportunityStatus.Draft &&
        <HeaderNotification
          title={opportunityQuery.opportunity.status}
          text='Only you can view this opportunity, it must be published before others can view.'
        />
      }
      <Container>
        <div>

          {/* Back Arrow */}
          <Breadcrumb href="/" text="Opportunities" />

          <div className="mt-5 max-w-[980px]">
            <OpportunitiesTitleBanner
              opportunity={opportunityQuery?.opportunity}
              isLoading={isOpportunityLoading}
              handleApply={handleApply}
              applyDisabled={isApplyDisabled}
              disabledMessage={applyDisabledMessage!}
            />
          </div>

          <div className="w-full max-w-[800px] mt-10 mb-5">
            <DateBanner
              isLoading={isOpportunityLoading}
              isoDate={opportunityQuery?.opportunity.starts_at}
            />
          </div>

          <div>

            {/* Images */}
            <OpportunityImages
              isLoading={isOpportunityLoading}
              urls={opportunityQuery?.opportunity?.images?.map((image) => image.url)}
              images={opportunityQuery?.opportunity.images}
            />

            {/* Types and Socials */}
            <div className="flex justify-between w-full max-w-[800px] mt-5 border-divider border-b-[1px] border-x-[1px] lg:p-5 p-2.5 rounded-b-lg">
              <div className="max-w-[240px] lg:max-w-[540px]">
                <OpportunityTags
                  opportunityTypes={opportunityQuery?.opportunity.opportunityTypes}
                  isLoading={isOpportunityLoading}
                />
              </div>
              {opportunityQuery?.opportunity
                && <SocialBar opportunity={opportunityQuery?.opportunity} />
              }
            </div>

          </div>

          {/* Opportunity Manager section */}
          <div className="w-full max-w-[800px] mt-8 lg:px-5">
            <OpportunityManagerCard
              isLoading={isOpportunityLoading}
              logoSrc={opportunityQuery?.opportunity.organisation?.organisation_image?.url}
              organisation={opportunityQuery?.opportunity.organisation?.name}
              href={opportunityQuery?.opportunity.organisation?.website}
            />
          </div>

          <hr className="bg-text-gray mt-9 mb-10 w-full max-w-[800px]" />

          {/* Opportunity Information */}
          <OpportunityInformation
            opportunity={opportunityQuery?.opportunity}
            isLoading={isOpportunityLoading}
            onReportClick={() => setIsReportModalOpen(true)}
            handleApply={handleApply}
            disabledMessage={applyDisabledMessage!}
            applyDisabled={isApplyDisabled}
          />

          {/* Apply Now Button */}
          <div className="mx-auto lg:mt-32 mt-8 max-w-[940px] w-full">
            <div className="lg:py-10 p-4 lg:px-[60px] lg:h-[161px] flex lg:flex-row flex-col lg:justify-between rounded-lg gap-4 border border-divider">
              <div className="my-auto">
                <Title className="hidden lg:flex leading-none mt-0 mb-4">Ready to make a difference?</Title>
                <Title className="flex !text-lg lg:hidden ">Ready to make a difference?</Title>
                <p>Get involved and get volunteering</p>
              </div>
              <div className="my-auto">
                { isApplyDisabled && <Tooltip
                  anchorId={`apply-button-footer-${opportunityQuery?.opportunity?.id}`}
                  content={applyDisabledMessage!}
                  style={{ maxWidth: '20rem' }}
                /> }
                <Button
                  id={`apply-button-footer-${opportunityQuery?.opportunity?.id}`}
                  onClick={handleApply}
                  disabled={isApplyDisabled}
                >
                  Apply Now
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Container>

      {/* Featured Opportunities Section */}
      <div className="mt-[100px] max-w-[1268px] mx-auto pb-28">
        <Title>Explore other Opportunities</Title>
        <div className="mt-8">
          <FeaturedOpportunitiesSwiper
            onClick={() => window.scrollTo(0, 0)}
            isCentered={false}
            opportunities={similarOpportunitiesQuery?.similarOpportunities.data}
            isLoading={isSimilarOpportunitiesLoading || isOpportunityLoading}
          />
        </div>
      </div>

      <ReportOpportunityModal
        isOpen={isReportModalOpen}
        onClose={() => setIsReportModalOpen(false)}
        opportunity={opportunityQuery?.opportunity}
      />

      <RegisterModal
        isOpen={isRegisterModalOpen}
        onClose={() => setIsRegisterModalOpen(false)}
        slug={opportunityQuery?.opportunity.slug}
      />

    </div>
  )
}

export default OpportunitiesShow
