import { useState } from 'react'
import Button from '../../Button'
import MultiSelect from '../../../components/MultiSelect'
import MagnifyingGlassIcon from '../../../icons/magnifyingGlassIcon'
import CalendarIcon from '../../../icons/calendarIcon'
import TagIcon from '../../../icons/tagIcon'
import { OpportunityType } from '@ec/types'
import { Popover } from '@headlessui/react'
import RangeDatePicker, { isoDateRange } from '../../../components/DatePicker/Range'
import dayjs from 'dayjs'
import useScreenWidth from '../../../hooks/useScreenWidth'

export type OpportunitySearchProps = {
  search?: string
  types?: string[]
  fromDate?: string
  toDate?: string
  withinDistance?: number
}

type RangeProps = {
  minimum: number
  maximum: number
}

type PropTypes = {
  onSearch: (e: OpportunitySearchProps) => void
  availableTypes?: OpportunityType[]
  distanceRange?: RangeProps
  isLoading?: boolean
}

const OpportunitySearch = ({
  onSearch,
  isLoading = false,
  availableTypes = [],
  distanceRange,
}: PropTypes) => {
  // const defaultDistance: number = 15

  const { isMobile } = useScreenWidth(1024)

  const [inputValue, setInputValue] = useState('')
  const [selectedTypes, setSelectedTypes] = useState<string[]>([])
  const [selectedDateRange, setSelectedDateRange] = useState<isoDateRange>({ from: undefined, to: undefined })
  // const [selectedDistance] = useState<number | undefined>()

  const handleOnSearch = (form: any) => {
    form.preventDefault()

    onSearch({
      search: inputValue,
      types: selectedTypes,
      fromDate: selectedDateRange.from,
      toDate: selectedDateRange.to,
    })
  }

  return (
    <div className="bg-white rounded-md lg:rounded-[20px] px-2 lg:px-2.5 shadow-xl border border-divider">

      <form onSubmit={(form) => handleOnSearch(form)} className="flex">

        <div className="flex flex-wrap sm:items-center justify-between sm:gap-2 w-full min-w-0">

          {/* Search */}
          <div className="flex sm:items-center lg:h-full flex-grow py-2">
            <input
              value={inputValue}
              onChange={(event) => setInputValue(event.currentTarget.value)}
              type="text"
              placeholder="Search Opportunities"
              className="transition-all relative pl-3 lg:pl-[36px] z-[0] border-b-divider border-b-1 outline-none text-sm font-medium w-full py-4 lg:py-0 lg:h-full focus:bg-gray-100 bg-gray-100 lg:bg-transparent rounded-[12px]"
            />
            <MagnifyingGlassIcon className="absolute left-3.5 hidden lg:flex lg:w-4 lg:h-4 lg:mx-2.5 text-primary-blue" />
          </div>

          {/* Filters */}
          <div className="flex ml-auto lg:h-full py-2">

            {/* Distance */}
            {/* <Popover className="flex relative w-full min-w-max">
              <Popover.Button className={`flex items-center border-l border-input px-4 ${!selectedDistance ? 'text-placeholder' : 'lg:text-text-gray'}`} disabled={isLoading}>
                <p className="hidden lg:flex lg:font-medium text-sm">
                  {selectedDistance ?? defaultDistance} Miles
                </p>
                <MapPinIcon className="w-5 h-5 lg:hidden" />
              </Popover.Button>
              <Popover.Panel className="absolute w-72 rounded-lg bg-white p-3 lg:p-4 border border-divider shadow-lg -left-[123px] lg:-left-16 top-[52px]">
                <Range
                  defaultValue={defaultDistance}
                  value={selectedDistance}
                  min={distanceRange?.minimum}
                  max={distanceRange?.maximum}
                  onChange={(event) => setSelectedDistance(parseInt(event.currentTarget.value))}
                  suffix={selectedDistance === 1 ? 'Mile' : 'Miles'}
                />
              </Popover.Panel>
            </Popover> */}

            {/* Opportunity Types */}
            <MultiSelect
              disabled={isLoading}
              triggerClassName="max-w-[200px] lg:min-w-[200px] truncate block items-center w-full border-l border-input px-4 lg:px-8 focus:outline-none focus-visible:bg-gray-100 text-sm font-medium"
              triggerFocusClassName="bg-gray-100"
              trigger={
                <div className={`flex ${selectedTypes.length > 0 ? 'lg:text-text-gray' : 'text-placeholder'} `}>
                  <p className="hidden lg:flex lg:font-medium lg:truncate text-sm">
                    Opportunity Types
                  </p>
                  <div className="flex lg:hidden gap-1">
                    <span>Types</span>
                  </div>
                </div>
              }
              positionClassName="scale-[0.6] sm:scale-100 top-5 sm:top-12 -left-[115px] lg:-left-16"
              items={availableTypes.map((type) => ({ id: type.id, text: type.name, unavailable: false }))}
              onChange={(types) => setSelectedTypes(types)}
              showSelection={!isMobile}
            />

            {/* Date Selection */}
            <Popover className="flex relative w-full min-w-max lg:min-w-[172px] max-w-[172px] mr-3">
              {({ open }) => (
                <>
                  <Popover.Button disabled={isLoading} className={`flex items-center w-full border-l border-input text-sm px-4 lg:px-8 focus:outline-none focus-visible:bg-gray-100 ${open ? 'bg-gray-100' : ''} ${selectedDateRange.to !== undefined ? 'lg:text-text-gray' : 'text-placeholder'}`}>
                    <p className="hidden lg:block lg:font-medium text-center w-full">
                      {selectedDateRange.from ? `${dayjs(selectedDateRange.from).format('D MMM')} ${selectedDateRange.to !== selectedDateRange.from ? '- ' + dayjs(selectedDateRange.to).format('D MMM') : ''}` : 'Select Dates'}
                    </p>
                    <div className="flex lg:hidden gap-1">
                      <span>Dates</span>
                    </div>
                  </Popover.Button>
                  <Popover.Panel>
                    <RangeDatePicker
                      dates={selectedDateRange}
                      className="absolute bg-white p-2 rounded-md shadow-md sm:top-9 border border-divider scale-75 sm:scale-100 left-[-130px] sm:left-[-250px] lg:-left-[132px]"
                      onChange={(date) => setSelectedDateRange(date)}
                    />
                  </Popover.Panel>
                </>
              )}
            </Popover>

            <Button
              rounded="rounded-xl"
              isLoading={isLoading}
            >
              <div>
                <p className="hidden lg:flex ">
                  Search
                </p>
                <MagnifyingGlassIcon className="w-5 h-5 lg:hidden" />
              </div>
            </Button>

          </div>

        </div>

      </form>

    </div>
  )
}

export default OpportunitySearch