import { gql } from '@apollo/client'
import { USER_FIELDS } from '../fragments'

export const GET_USER = gql`
  ${USER_FIELDS}
  query ($id: ID!, $opportunitiesPage: Int!, $opportunitiesFirst: Int!) {
    getUser(id: $id) {
      ...UserFields
      opportunities (page: $opportunitiesPage, first: $opportunitiesFirst) {
        data {
          id
          slug
          status
          title
          excerpt
          city
          county
          created_at
          images {
            id
            url
          }
          opportunityTypes {
            id
            name
          }
        }
        paginatorInfo {
          count
          currentPage
          lastPage
        }
      }
    }
  }
`

export const GET_VOLUNTEER_USERS = gql`
  ${USER_FIELDS}
  query ($first: Int!, $page: Int!, $filter: VolunteerFilterInput) {
    getVolunteers (first: $first, page: $page, filter: $filter) {
      data {
        ...UserFields
        opportunities_count
      }
      paginatorInfo {
        total
        currentPage
        lastPage
      }
    }
  }
`

export const GET_USER_SUBMISSIONS = gql`
  query($submissionsFirst: Int!, $submissionsPage: Int, $submissionsFilter: SubmissionsFilter) {
    getAuthUser {
      id
      submissions(first: $submissionsFirst, page: $submissionsPage, filters: $submissionsFilter) {
        data {
          id
          status
          opportunity {
            id
            status
            slug
            title
            content
            excerpt
            skills
            duration
            street_address
            postcode
            status
            city
            county
            country
            second_line
            starts_at
            deadline_at
            ends_at
            cancelled_at
            deadline_at
            manager_instructions
            images {
              id
              url
            }
            opportunityTypes {
              id
              name
            }
          }
        }
        paginatorInfo {
          currentPage
          lastPage
          total
        }
      }
    }
  }
`
