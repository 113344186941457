import { useMutation } from '@apollo/client'
import { LOGIN_VIA_TWO_FACTOR_AUTHENTICATION } from '@ec/apollo/src/mutations/auth'
import { UserType } from '@ec/types'
import { Button, Input } from '@ec/ui'
import { useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { loginViaTwoFactorAuthentication as login } from 'slices/auth'

type TwoFactorChallengeProps = {
  code: string,
}

const TwoFactorChallengePage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { register, handleSubmit } = useForm<TwoFactorChallengeProps>()

  const [loginViaTwoFactorAuthentication, { data: loginData, loading: isLoginLoading, error: loginError }] = useMutation<{ loginViaTwoFactorAuthentication: UserType }>(LOGIN_VIA_TWO_FACTOR_AUTHENTICATION)


  const onSubmit: SubmitHandler<TwoFactorChallengeProps> = (form) => {
    loginViaTwoFactorAuthentication({
      variables: {
        code: form.code,
      },
    })
  }

  useEffect(() => {
    if (loginData) {
      dispatch(login(loginData.loginViaTwoFactorAuthentication))
      navigate('/')
    }
  }, [loginData])

  return (
    <div className="flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="text-center sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-3xl font-bold tracking-tight text-gray-900">Two Factor Authentication</h2>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">Enter the code displayed in your authenticator app</p>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 sm:rounded-lg sm:px-10 rounded-lg">

          <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>

            <div className="flex gap-4">
              <Input
                label="Code"
                error={loginError && 'Invalid code'}
                required
                {...register('code')}
              />
            </div>
            <Button block isLoading={isLoginLoading}>
              Confirm Code
            </Button>

            <div className="cf-turnstile" data-sitekey={process.env.REACT_APP_CLOUDFLARE_TURNSTILE_SITE_KEY}></div>
          </form>
        </div>
      </div>

    </div>
  )
}

export default TwoFactorChallengePage