import { Opportunity } from '@ec/types'
import { regionalAddress } from '../../../helpers/strings/address'
import ContentCard from '../../Cards/ContentCard'

type PropTypes = {
  isLoading?: boolean
  alwaysShowSkeletons?: boolean
  opportunities?: Opportunity[]
  altText?: string
  gridColsClassNames?: string
  keyPrefix?: string
}

const OpportunitiesGrid = ({
  isLoading,
  altText,
  alwaysShowSkeletons = false,
  opportunities,
  gridColsClassNames,
  keyPrefix = 'opportunities-',
}: PropTypes) => {

  const gridCols = gridColsClassNames ? gridColsClassNames : 'grid-cols-1 md:grid-cols-2 lg:grid-cols-3'

  return (
    <div className={`w-full mt-8 grid ${gridCols} gap-x-[34px] lg:gap-y-[50px] gap-y-12`}>
      {
        isLoading
          ? alwaysShowSkeletons
            ? getSkeletonCards()
            : !opportunities && getSkeletonCards()
          : opportunities && opportunities.length > 0
            ? opportunities.map((opportunity) => {
              return (
                <div key={`${keyPrefix}${opportunity.id}`} className="lg:px-0">
                  <ContentCard
                    title={opportunity.title}
                    heading={regionalAddress({ city: opportunity.city, county: opportunity.county })}
                    content={opportunity.excerpt}
                    contentMadeAt={opportunity.deadline_at}
                    imageSource={opportunity.images[0].url}
                    tags={opportunity.opportunityTypes}
                    href={`/opportunities/${opportunity.slug}`}
                  />
                </div>
              )
            })
            : altText && <p className="text-text-gray">{altText}</p>
      }
    </div>
  )
}

// Skeleton cards for generic browsing
const getSkeletonCards = () => (
  <>
    {
      Array.from(Array(6)).map((index, key) => (
        <ContentCard key={`opportunities-skeleton-${key}`} isLoading={true} />
      ))
    }
  </>
)

export default OpportunitiesGrid