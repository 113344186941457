import { Link } from 'react-router-dom'
import Logo from '@ec/ui/src/svgs/logo'
import { BoltIcon, FacebookIcon, TwitterIcon } from '@ec/ui/src/icons'
import Image from '../Image'

const logo = require('@ec/ui/src/public/logo-cic.jpeg')

const Footer = () => {
  return (
    <div className="relative bg-white z-1">
      <div className="w-full max-w-[1268px] mx-auto flex flex-col mt-auto pt-28 pb-10 px-5">
        <hr className="max-w-[1000px] w-full mx-auto mb-[52px]" />

        <div className="flex flex-col lg:flex-row gap-y-[40px] gap-x-[86px]">
          <div>
            <Link to="/" className="block max-w-[180px] lg:max-w-[260px] w-full">
              <div className="max-w-[180px] w-full">
                <Logo className="w-full" />
              </div>
            </Link>

            <div className="flex gap-2.5 mt-2.5">
              <FacebookIcon className="h-6 w-6" />
              <TwitterIcon className="h-6 w-6" />
            </div>
          </div>

          <div className="flex gap-y-[40px] gap-x-[100px] xl:gap-[176px] flex-wrap">
            <div className="text-sm lg:text-base font-medium">
              <p className="font-semibold text-base lg:text-lg mb-4 lg:mb-7">New to GoVolunteering</p>

              <div className="flex flex-col space-y-5">
                <a href="https://engagedcommunities.co.uk/solutions/" className="hover:underline">Products</a>
                <a href="#" className="hover:underline">Product Pricing</a>
                <a href="https://engagedcommunities.co.uk/contact/" className="hover:underline">Contact Us</a>
              </div>
            </div>

            <div className="text-sm lg:text-base font-medium">
              <p className="font-semibold text-base lg:text-lg mb-4 lg:mb-7">About GoVolunteering</p>

              <div className="flex flex-col space-y-5">
                <a href="https://engagedcommunities.co.uk/about/" className="hover:underline">Our Story</a>
                <a href="https://engagedcommunities.co.uk/terms-of-service/" className="hover:underline">Terms of Service</a>
                <a href="https://engagedcommunities.co.uk/terms-of-use/" className="hover:underline">Terms of Use</a>
                <a href="https://engagedcommunities.co.uk/privacy-policy/" className="hover:underline">Privacy Policy</a>
                <a href="https://engagedcommunities.co.uk/cookies/" className="hover:underline">Cookie Policy</a>
                <a href={`${process.env.REACT_APP_MARKETPLACE_PORTAL_URL}/use-restrictions-policy`} className="hover:underline">Use Restrictions Policy</a>
              </div>
            </div>
          </div>

        </div>

        <div className="w-full flex md:justify-end items-center gap-4 mt-20">
          <div className="relative ml-[4px] w-[14px] lg:w-[24px] h-[14px] lg:h-[24px] bg-primary-yellow">
            <BoltIcon className='mt-[-10px] w-[25px] lg:w-[35px] h-[25px] lg:h-[35px] text-black' />
          </div>
          <p className="font-semibold text-primary-blue text-sm sm:text-base lg:text-lg">Powered By</p>
          <Image source={logo} className="w-full max-w-[100px] sm:max-w-[180px]" />
        </div>
      </div>
    </div>

  )
}

export default Footer