import { useEffect, useRef, useState } from 'react'
import { Opportunity } from '@ec/types'
import { getUserLocation } from 'helpers/user-location'
import Map from 'components/Map'
import MapMarker from 'components/MapMarker'
import { Coords } from 'google-map-react'
import { useDebouncedCallback } from 'use-debounce'

export type MapBounds = {
  top: number
  bottom: number
  left: number
  right: number
}

type PropTypes = {
  opportunities?: Opportunity[]
  onMapDragEnd?: (bounds: MapBounds) => void
  className?: string
}

const OpportunityMap = ({
  opportunities,
  onMapDragEnd,
  className = '',
}: PropTypes) => {

  const mapContainer = useRef<HTMLDivElement>(null)

  const [isMapFullScreen, setIsMapFullScreen] = useState<boolean>(false)
  const [center, setCenter] = useState<Coords>()

  useEffect(() => {
    getUserLocation((position) => {
      setCenter({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      })
    },
    () => {
      // Default to london if location can't be retrieved
      setCenter({
        lat: 51.509865,
        lng: -0.118092,
      })
    })
  }, [])

  const handleMapSize = () => {
    if (mapContainer.current && opportunities && opportunities.length > 0) {
      let height: any = window.innerHeight - mapContainer.current.getBoundingClientRect().top

      if (window.pageYOffset > 400) {

        // Only set fullscreen when over 1024 pixels wide
        if (window.innerWidth >= 1024) {
          if (!isMapFullScreen) {
            setIsMapFullScreen(true)
          }

          mapContainer.current.style.height = window.innerHeight + 'px'
        }

      } else {
        if (isMapFullScreen) {
          setIsMapFullScreen(false)
        }

        // Hardcode mobile/tablet height when under 1024 pixels wide
        if (window.innerWidth >= 1024) {
          mapContainer.current.style.height = height + 'px'
        } else {
          mapContainer.current.style.height = '720px'
        }
      }

    }
  }

  const handleMapChange = useDebouncedCallback(
    (map: any) => {
      const topRight: { latitude: number, longitude: number } = {
        latitude: map.getBounds().getNorthEast().lat(),
        longitude: map.getBounds().getNorthEast().lng(),
      }

      const bottomLeft: { latitude: number, longitude: number } = {
        latitude: map.getBounds().getSouthWest().lat(),
        longitude: map.getBounds().getSouthWest().lng(),
      }

      if (!onMapDragEnd) return

      return onMapDragEnd({
        top: topRight.longitude,
        bottom: bottomLeft.longitude,
        left: bottomLeft.latitude,
        right: topRight.latitude,
      })
    }, 1000,
  )

  useEffect(() => {
    if (mapContainer.current) {
      mapContainer.current.style.height = window.innerHeight - mapContainer.current.getBoundingClientRect().top + 'px'
    }

    if (isMapFullScreen!) {
      handleMapSize()
    }

    window.addEventListener('scroll', handleMapSize)

    return () => window.removeEventListener('scroll', handleMapSize)
  }, [isMapFullScreen])

  return (
    <div ref={mapContainer} className={`${className} bg-muted ${isMapFullScreen ? 'fixed top-0 bottom-0 right-0 w-[calc(100%-554px)] 2xl:w-[calc(100%-954px)]' : 'h-full w-full'}`}>
      {
        center &&
        <Map
          center={center}
          zoom={11}
          onDragEnd={(map) => handleMapChange(map)}
        >
          {
            opportunities && opportunities.map((opportunity) => {
              const { latitude, longitude } = opportunity

              if (latitude && longitude) {
                return <MapMarker
                  key={`map-marker-${opportunity.id}`}
                  opportunity={opportunity}
                  lat={latitude}
                  lng={longitude}
                />
              }
              
              return <></>
            })
          }
        </Map>
      }
    </div>
  )
}

export default OpportunityMap