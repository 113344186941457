import { UserType } from '@ec/types'
import { useMutation } from '@apollo/client'
import { Modal, Button } from '@ec/ui'
import { SubmitHandler, useForm } from 'react-hook-form'
import { CREATE_DATA_DELETION_REQUEST } from '@ec/apollo/src/mutations/auth'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'

type CancellationProps = {
  reason: string
}

type PropTypes = {
  isOpen: boolean
  onClose: () => void
  onError: () => void
  onSuccess: () => void
}

const DeleteAccountModal = ({
  isOpen = false,
  onClose,
  onError,
  onSuccess,
}: PropTypes) => {
  const { handleSubmit } = useForm<CancellationProps>()

  const [createDataDeletionRequest, { loading: accountDeletionLoading }] = useMutation<{ createDataDeletionRequest: UserType }>(CREATE_DATA_DELETION_REQUEST)

  const onSubmit: SubmitHandler<CancellationProps> = (form) => {
    createDataDeletionRequest({
      onCompleted: () => {
        onClose()
        onSuccess()
      },
      onError: () => {
        onClose()
        onError()
      },
    })
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="large">

      <Modal.Header title="Delete Account" />

      <form onSubmit={handleSubmit(onSubmit)}>

        <Modal.Content>
          <div className="flex flex-col gap-4">
            <div className="flex gap-2 p-4 bg-yellow-200 rounded-lg outline outline-yellow-300">
              <div>
                <ExclamationTriangleIcon className="h-6"/>
              </div>
              <p>
                If your request is approved, you will lose access to this account and your data cannot be recovered
              </p>
            </div>
            <p>
              Are you sure you want to delete your account?
            </p>
          </div>
        </Modal.Content>

        <Modal.Footer className="flex flex-wrap gap-3 justify-end">
          <Button type="button" variant="secondary" onClick={onClose}>Cancel</Button>
          <Button type="submit" variant="danger" isLoading={accountDeletionLoading}><strong>Request</strong></Button>
        </Modal.Footer>

      </form>

    </Modal>

  )
}

export default DeleteAccountModal