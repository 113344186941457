import { Button } from '@ec/ui'
import { Link } from 'react-router-dom'
import Navbar from '@ec/ui/src/components/Navbar'
import Logo from '@ec/ui/src/svgs/logo'


const GuestNavbar = () => {
  const links: { url: string, text: string, root?: string }[] = [
    { url: '/', text: 'Opportunities', root: '/opportunities/' },
  ]

  return (
    <Navbar
      logo={
        <Link to="/" className="block max-w-[180px] lg:max-w-[260px] w-full">
          <Logo className="w-full" />
        </Link>
      }
      links={links}
      actions={
        <>
          <Link to="/login">
            Login
          </Link>

          <Button type="button" variant="primary-alt" href='/register'>
            Register
          </Button>
        </>

      }

    />
  )
}

export default GuestNavbar