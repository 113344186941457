import { useEffect, useState } from 'react'

const useScreenWidth = (mobileWidth = 768) => {
  const [width, setScreenWidth] = useState<number>(0)
  const [isMobile, setIsMobile] = useState<boolean>(false)

  useEffect(() => {
    const updateWindowDimensions = () => {
      setScreenWidth(window.innerWidth)
      setIsMobile(window.innerWidth < mobileWidth)
    }
    updateWindowDimensions()
    window.addEventListener('resize', updateWindowDimensions)
    return () => window.removeEventListener('resize', updateWindowDimensions)
  }, [])

  return { width, isMobile }
}

export default useScreenWidth