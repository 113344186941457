import { Container } from '@ec/ui'
import { Link, Outlet, useLocation } from 'react-router-dom'

const AccountPageOutlet = () => {
  const location = useLocation().pathname

  const links: { url: string, text: string }[] = [
    { url: '/account/settings', text: 'Account Settings' },
    { url: '/account/authentication', text: 'Passwords and Authentication' },
    { url: '/account/notifications', text: 'Notifications and Preferences' },
  ]

  return (
    <Container>

      <div className="flex gap-10 border-b border-divider mb-[1.875rem] overflow-x-auto">
        {
          links.map((link, index) => (
            <Link
              key={index}
              to={link.url}
              className={`transition-all whitespace-nowrap font-medium text-sm pb-4 ${link.url === location ? 'border-b border-primary-pink text-primary-pink' : 'text-placeholder'}`}
            >
              {link.text}
            </Link>
          ))
        }
      </div>

      <Outlet />

    </Container>
  )
}

export default AccountPageOutlet