import { OpportunityType } from "@ec/types"
import CategoryIcon from "../../icons/categoryIcon"

type PropTypes = {
  tags: OpportunityType[]
}

const OpportunityTagsExcerpt = ({ tags }: PropTypes) => {
  return (
    <div className="flex items-center gap-2">
      <div className="relative ml-[4px] w-[16px] h-[16px] bg-primary-yellow">
        <CategoryIcon className='mt-[-4px] ml-[-2px] w-4 h-4 text-black' />
      </div>
      <p className='text-sm font-medium text-gray'>{tags.map((type) => type.name).join(', ')}</p>
    </div>
  )
}

export default OpportunityTagsExcerpt