import { gql } from '@apollo/client'
import { OPPORTUNITY_FIELDS } from '../fragments'

export const GET_OPPORTUNITIES = gql`
  ${OPPORTUNITY_FIELDS}
  query ($first: Int!, $page: Int, $filters: OpportunitiesFilter) {
    opportunities(first: $first, page: $page, filters: $filters) {
      data {
        ...OpportunityFields
      }
      paginatorInfo {
        currentPage
        lastPage
      }
    }
  }
`

export const GET_OPPORTUNITY = gql`
  ${OPPORTUNITY_FIELDS}
  query ($slug: String, $id: ID) {
    opportunity(slug: $slug, id: $id) {
      ...OpportunityFields
      organisation {
        id
        name
        website
        organisation_image {
          id
          url
        }
      }
    }
  }
`

export const GET_OPPORTUNITY_FORM = gql`
  query ($slug: String, $id: ID, $filters: FormFieldFilters) {
    opportunity(slug: $slug, id: $id) {
      id
      slug
      status
      title
      excerpt
      city
      county
      created_at
      images {
        id
        url
      }
      opportunityTypes {
        id
        name
      }
      form {
        id
        fields (filters: $filters) {
          __typename @skip(if: true)
          id
          title
          type
          is_enabled
          is_required
          is_default
        }
      }
    }
  }
`
export const GET_OPPORTUNITY_FILTERS = gql`
  query ($fromOrigin: [Float]) {
      opportunityFilters (fromOrigin: $fromOrigin) {
          opportunityTypes {
              id
              name
          }
          distances {
            minimum
            maximum
        }
      }
  }
`

export const GET_SPONSORED_OPPORTUNITIES = gql`
  ${OPPORTUNITY_FIELDS}
  query ($first: Int!) {
    sponsoredOpportunities (first: $first) {
      data {
        id,
        opportunity {
          ...OpportunityFields
        }
      }
    }
  }
`

export const GET_SIMILAR_OPPORTUNITIES = gql`
  query ($id: ID!, $first: Int!) {
    similarOpportunities (id: $id, first: $first) {
      data {
        id
        slug
        status
        title
        excerpt
        city
        county
        created_at
        images {
          id
          url
        }
        opportunityTypes {
          id
          name
        }
      }
    }
  }
`

export const GET_PRESENT_OPPORTUNITIES = gql`
  ${OPPORTUNITY_FIELDS}
  query ($userId: ID, $first: Int!) {
    presentOpportunities(userId: $userId, first: $first) {
      data {
        ...OpportunityFields
      },
      paginatorInfo {
          currentPage
          lastPage
          count
      }
    }
  }
`

export const GET_VOLUNTEER_MANAGER_APPLICANTS = gql`
query($userFilters: UsersFilter, $opportunitiesFilters: OpportunitiesFilter, $submissionsFirst: Int!, $submissionsPage: Int){
	getAuthUser{
    id
    name
    organisation {  
      opportunities(filters: $opportunitiesFilters) {
        form {
          id
          submissions(filters: $userFilters, first: $submissionsFirst, page: $submissionsPage) {
            data {
              status
              data {
                type
                question
                answer
              }
              user {
                id
                name
                email
                has_dbs
                has_drivers_license
                date_of_birth
              }
            }
            paginatorInfo {
              currentPage
              lastPage
              count
            }
          }
        }
        images {
          url
        }
        street_address
        city
        status
        postcode
        opportunityTypes {
          name
        }
        id
        capacity
        title
        slug
        status
        acceptedSubmissions
        deadline_at
        starts_at
      }
    }
	}
}
`

export const GET_VOLUNTEER_MANAGER_OPPORTUNITIES = gql`
query($opportunitiesFilters: OpportunitiesFilter){
	getAuthUser{
    id
    name
    organisation {
      opportunities(filters: $opportunitiesFilters) {
        form {
          id
        }
        images {
          url
        }
        street_address
        city
        status
        postcode
        opportunityTypes {
          name
        }
        id
        capacity
        title
        slug
        status
        acceptedSubmissions
        deadline_at
        starts_at
      }
    }
	}
}
`

export const GET_VOLUNTEER_MANAGER_OPPORTUNITY_BASIC = gql`
query {
  getAuthUser {
    organisation {
      opportunities {
        title
        slug
        id
      }
    }
  }
}
`