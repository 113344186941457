import { Title, Heading } from '../components/Headings'

const useRestrictionsPolicy = () => {
  return (
    <div className="max-w-[640px] mx-auto px-5">
      <Title>Use Restrictions</Title>

      <p className="text-secondary-blue mb-5 font-medium">Last updated: 3rd January 2023</p>

      <p>
        Lots of teams use GoVolunteering, an Engaged Communities C.I.C. product. We
        are proud to give them a better way to work. We also recognise that however
        good the maker&apos;s intentions, technology can amplify the ability to cause great
        harm. That&apos;s why we&apos;ve established this policy. We feel an ethical obligation to
        counter such harm: both in terms of dealing with instances where
        GoVolunteering is used (and abused) to further such harm, and to state
        unequivocally that the products we make at Engaged Communities C.I.C. are
        not safe havens for people who wish to commit such harm. If you have an
        account with any of our products, you can&apos;t use them for any of the restricted
        purposes listed below.
      </p>

      <Heading className="mt-10 mb-3">Restricted purposes</Heading>
      <ul className="list-disc space-y-4">
        <li>
          <strong>Violence, or threats thereof:</strong> If an activity qualifies as violent crime in the
          UK or where you live, you may not use Engaged Communities C.I.C.
          products to plan, perpetrate, or threaten that activity.
        </li>

        <li>
          <strong>Child exploitation, sexualisation, or abuse:</strong> We don’t tolerate any
          activities that create, disseminate, or otherwise cause child abuse.
        </li>

        <li>
          <strong>Hate speech:</strong> You cannot use our products to advocate for the
          extermination, domination, or oppression of people.
        </li>

        <li>
          <strong>Harassment:</strong> Intimidating or targeting people or groups through
          repeated communication, including using racial slurs or dehumanising
          language, is not welcome at Engaged Communities C.I.C. or
          GoVolunteering.
        </li>

        <li>
          <strong>Doxing:</strong> If you are using Engaged Communities C.I.C. products to share
          other peoples’ private personal information for the purposes of
          harassment, we will not tolerate this.
        </li>

        <li>
          <strong>Malware or spyware:</strong> Code for good, not evil. If you are using our
          products to make or distribute anything that qualifies as malware or
          spyware — including remote user surveillance, please stop immediately.
        </li>

        <li>
          <strong>Phishing or otherwise attempting fraud:</strong> It is not okay to lie about who
          you are or who you affiliate with to steal from, extort, or otherwise harm
          others.
        </li>

        <li>
          <strong>Spamming:</strong> No one wants unsolicited commercial emails. We don’t
          tolerate people (including their bots) using Engaged Communities C.I.C.
          products for spamming purposes.
        </li>

        <li>
          <strong>Cybersquatting:</strong>   We don’t like username extortionists. If you purchase an
          Engaged Communities C.I.C. product account in someone else’s name
          and then try to sell that account to them, cybersquatting accounts and
          these accounts are subject to immediate cancellation.
        </li>

        <li>
          <strong>Infringing on intellectual property:</strong> You can’t use Engaged Communities
          C.I.C. products to make or disseminate work that uses the intellectual
          property of others.
        </li>
      </ul>

      <p className="mt-3">
        While our use restrictions are comprehensive, they can’t be exhaustive — it’s
        possible an offense could defy categorisation, present for the first time, or
        illuminate a moral quandary we hadn’t yet considered. That said, we hope the
        overarching spirit is clear: Engaged Communities C.I.C. and the GoVolunteering
        platform is not to be harnessed for harm, whether mental, physical, personal or
        civic. Different points of view — philosophical, religious, and political — are
        welcome, but ideologies like white nationalism, or hate-fuelled movements
        anchored by oppression, violence, abuse, extermination, or domination of one
        group over another, will not be accepted here.
      </p>

      <Heading className="mt-10 mb-3">How to report abuse</Heading>
      <p>
        For cases of suspected malware, spyware, phishing, spamming, and
        cybersquatting, please alert us at <a href="mailto:contact@engagedcommunities.co.uk" className="underline text-primary-blue">contact@engagedcommunities.co.uk</a> If
        you’re not 100% sure if something rises to the level of our use restrictions policy,
        report it anyway.
        Please share as much as you are comfortable with about the account, the
        content or behaviour you are reporting, and how you found it. Sending us a URL
        or screenshots is really helpful. If you need a secure file transfer, let us know and
        we will send you a link. We will not disclose your identity to anyone associated
        with the reported account.
        Someone on our team will respond within one business day to let you know
        we’ve begun investigating. We will also let you know the outcome of our
        investigation (unless you ask us not to, or we are not allowed to under law).
      </p>

    </div>
  )
}

export default useRestrictionsPolicy