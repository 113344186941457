import { useMutation } from '@apollo/client'
import { LoginResponseType, RegisterType } from '@ec/types'
import { Button, DateInput, Input, Modal, Toast } from '@ec/ui'
import { useEffect } from 'react'
import { useNotifier } from 'react-headless-notifier'
import { SubmitHandler, useForm } from 'react-hook-form'
import { REGISTER_VOLUNTEER } from '@ec/apollo/src/mutations/auth'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { login } from '@ec/marketplace/src/slices/auth'
import useErrorHandler from 'hooks/UseErrorHandler'

type PropTypes = {
  isOpen: boolean
  onClose: () => void
  slug?: string
}

const RegisterModal = ({ isOpen = false, onClose, slug }: PropTypes) => {
  const { register, handleSubmit, setError, setValue, clearErrors, formState: { errors } } = useForm<RegisterType>()
  const { notify } = useNotifier()
  const navigate = useNavigate()
  const errorHandler = useErrorHandler()
  const [registerMutation, { data: registerData, loading: isRegisterLoading, error: registerError }] = useMutation<{ registerVolunteer: LoginResponseType }>(REGISTER_VOLUNTEER)
  
  const dispatch = useDispatch()

  const onSubmit: SubmitHandler<RegisterType> = (form) => {
    registerMutation({
      variables: {
        input: {
          name: form.name,
          email: form.email,
          password: form.password,
          date_of_birth: form.date_of_birth,
        },
      },
    })
  }

  useEffect(() => {
    if(registerData && !registerError){
      onClose()
      notify(<Toast title="Success" message="Please check your e-mail inbox to verify your account." />)
      navigate(`/opportunities/${slug}`)
      dispatch(login(registerData.registerVolunteer))
    }
  }, [registerData])
  

  useEffect(() => {
    if (registerError) {
      errorHandler(registerError, setError)
    }
  }, [registerError])

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <form onSubmit={(e) => {
        e.preventDefault()
        clearErrors()
        handleSubmit(onSubmit)(e)
      }}>
        <Modal.Header title="Sign up to Go Volunteering" subtitle='Register to volunteer to opportunities'/>
        <Modal.Content>
          <div className="flex flex-col space-y-6">

            <Input
              label="Name"
              required
              {...register('name')}
              error={errors.name?.message}
            />

            <DateInput label="Date of Birth" onChange={(date) => setValue('date_of_birth', date)} error={errors.date_of_birth?.message} />

            <Input
              label="Email"
              required
              {...register('email')}
              error={errors.email?.message}
              expanding
            />

            <Input
              label="Password"
              type="password"
              required
              {...register('password')}
              error={errors.password?.message}
            />

          </div>
        </Modal.Content>
        <Modal.Footer className="flex flex-col gap-6 text-sm text-center">
          <Button block isLoading={isRegisterLoading}>Sign up</Button>
          <span className="text-primary-blue">Already have an account? <Link to="/login" className="underline">Log in</Link></span>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default RegisterModal