import { UserType } from '@ec/types'
import { Container, Image, SpinnerIcon } from '@ec/ui'
import { CameraIcon } from '@ec/ui/src/icons'
import { AcceptedUploadType } from 'helpers/enums/UploadFiles'
import { useRef } from 'react'
import { Controller } from 'react-hook-form'
import Skeleton from 'react-loading-skeleton'

type PreviewUrls = {
  profile?: string
  banner?: string
}

type PropTypes = {
  control?: any
  user?: UserType
  setPreviewUrls?: (PreviewUrls) => void
  previewUrls?: PreviewUrls
  isReadOnly?: boolean
  isLoading?: boolean
}

const ProfileHeader = ({ control, setPreviewUrls, previewUrls, user, isReadOnly = false, isLoading = true }: PropTypes) => {
  const profilePictureRef = useRef<any>(null)
  const profileBannerRef = useRef<any>(null)
  
  const commonProfilePictureClasses: string = 'absolute w-[80px] h-[80px] lg:w-[185px] lg:h-[185px] rounded-full border-4 border-white lg:top-[132px] top-[14px] lg:left-12 left-14 shadow-sm'
  const commonProfileBannerClasses: string = 'w-full h-[109px] lg:h-[218px]'

  return (
    <>
      {
        !isReadOnly ? (
          <Container className='relative max-h-[218px]'>
            <Controller
              name="profile_banner"
              control={control}
              render={({ field }) => {
                const handleChange = async (files: File[] | FileList | null) => {
                  if (files) {
                    const preview = URL.createObjectURL(files[0])
                    if (setPreviewUrls) setPreviewUrls({ profile: previewUrls?.profile, banner: preview })
                  }

                  field.onChange(files)
                }

                return (
                  <>
                    <button 
                      onClick={() => ( profileBannerRef.current?.click() )} 
                      type="button" 
                      className={`${commonProfileBannerClasses} focus:outline-primary-green relative group`}>
                      <div className="opacity-0 transition-all group-hover:opacity-100 absolute top-0 right-0 bg-white bg-opacity-75 p-2 m-1 rounded-md">
                        <CameraIcon className="w-5 h-5 text-text-gray" />
                      </div>
                      {
                        isLoading ? (
                          <div className="flex bg-muted w-full h-full">
                            <SpinnerIcon className="h-10 w-10 m-auto text-primary-green" />
                          </div>
                        ) : (
                          <Image 
                            source={previewUrls?.banner ?? user?.profile_banner?.url}
                            altText="profile-banner"
                            width={1200}
                            height={218}
                            className="w-full h-full object-cover"
                          />
                        )
                      }
                    </button>
                    <input 
                      type="file" 
                      accept={AcceptedUploadType.PROFILE_IMAGES}
                      className="hidden" 
                      ref={profileBannerRef} 
                      onChange={(input) => handleChange(input.target?.files)} 
                    />
                  </>
                )
              }}
            />
            <Controller
              name="profile_picture"
              control={control}
              render={({ field }) => {
                const handleChange = async (files: File[] | FileList | null) => {
                  if (files) {
                    const preview = URL.createObjectURL(files[0])
                    if (setPreviewUrls) setPreviewUrls({ profile: preview, banner: previewUrls?.banner })
                  }

                  field.onChange(files)
                }

                return (
                  <>
                    <button 
                      onClick={() => ( profilePictureRef.current?.click() )}
                      type="button" 
                      className={`bg-white ${commonProfilePictureClasses} focus:border-primary-green focus:outline-none group z-10`}>
                      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 opacity-0 group-hover:opacity-100 transition-all bg-white bg-opacity-75 p-2 rounded-md">
                        <CameraIcon className="w-5 h-5 text-text-gray" />
                      </div>
                      {
                        isLoading ? (
                          <div className="flex bg-muted w-full h-full rounded-full">
                            <SpinnerIcon className="h-10 w-10 m-auto text-primary-green" />
                          </div>
                        ) : (
                          <Image 
                            source={previewUrls?.profile ?? user?.profile_picture?.url}
                            altText="profile-picture"
                            width={185}
                            height={185}
                            className="object-cover w-full h-full rounded-full"  
                          />
                        )
                      }
                    </button>
                    
                    <input 
                      type="file" 
                      accept={AcceptedUploadType.PROFILE_IMAGES}
                      className="hidden" 
                      ref={profilePictureRef} 
                      onChange={(input) => handleChange(input.target?.files)} 
                    />
                  </>
                )
              }}
            />

          </Container>
        ) : (
          <Container className="relative max-h-[218px]">
            {
              isLoading 
                ? (
                  <div className={`flex ${commonProfileBannerClasses} bg-muted`}>
                    <div className="w-full -mt-1">
                      <Skeleton className="object-cover h-full max-h-[109px] lg:max-h-[218px] max-w-[1184px]" />
                    </div>
                  </div>
                ) : (
                  <Image 
                    source={user?.profile_banner?.url}
                    altText="profile-banner"
                    width={1200}
                    height={218}
                    className={`${commonProfileBannerClasses} object-cover`}
                  /> 
                )
            }
            {
              isLoading
                ? (
                  <div className={`flex bg-muted ${commonProfilePictureClasses} focus:border-primary-green focus:outline-none`}>
                    <div className="w-full -mt-1">
                      <Skeleton className="!rounded-full h-full max-w-[80px] max-h-[80px] lg:max-w-[185px] lg:max-h-[185px]" />
                    </div>
                  </div>
                ) : ( user?.profile_picture
                  ? <Image 
                    source={user?.profile_picture?.url}
                    altText="profile-picture"
                    width={185}
                    height={185}
                    className={`object-cover bg-white ${commonProfilePictureClasses}`} />
                  : <div className={`${commonProfilePictureClasses}`}>
                    <p className="h-full w-full justify-center bg-primary-blue text-blue-300 font-semibold lg:text-5xl flex items-center rounded-full">
                      {user?.name.split(' ').map(word => word[0]).slice(0,2)}
                    </p>
                  </div>
                )
            }
          </Container>
        )
      }
    </>
  )
}

export default ProfileHeader