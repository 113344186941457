import FormError from '../FormError'
import { ChangeEvent, FocusEventHandler, forwardRef, HTMLInputTypeAttribute, useState } from 'react'

type InputProps = {
  disabled?: boolean
  label?: string
  name?: string
  onBlur?: FocusEventHandler
  onChange?: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  onClick?: () => void
  isReadOnly?: boolean
  placeholder?: string
  required?: boolean
  type?: HTMLInputTypeAttribute
  value?: string
  width?: 'max-w-xs' | 'max-w-sm' | 'max-w-md' | 'max-w-lg' | 'max-w-full'
  error?: string
  maxLength?: number
  step?: number
  min?: number | string
  max?: number | string
  showOptionalLabel?: boolean
  expanding?: boolean
  inputMode?: 'email' | 'search' | 'tel' | 'text' | 'url' | 'none' | 'numeric' | 'decimal' | undefined
}

const Input = forwardRef<any, InputProps>(function Input({
  disabled = false,
  label,
  name,
  onBlur,
  onChange,
  onClick,
  isReadOnly,
  required = false,
  placeholder,
  type = 'text',
  value,
  width = 'full',
  error,
  maxLength,
  step,
  min,
  max,
  showOptionalLabel = false,
  inputMode = undefined,
  expanding = false,
}, ref) {

  const [inputHeight, setInputHeight] = useState('auto')

  function handleTextareaChange(event) {
    const textarea = event.target
    textarea.style.height = 'auto'
    textarea.style.height = `${textarea.scrollHeight+2}px`
    setInputHeight(textarea.style.height)
  }

  return (
    <div className={`w-full ${width} relative`}>
      {
        label !== undefined &&
        <label htmlFor={name} className="block text-sm font-medium text-gray-700">
          {label}{showOptionalLabel && <span className="text-xs text-placeholder ml-1"> Optional</span>}
        </label>
      }
      <div className="mt-1">
        { expanding
          ? <textarea
            rows={1}
            ref={ref}
            readOnly={isReadOnly}
            disabled={disabled}
            style={{height: inputHeight}}
            name={name}
            onBlur={onBlur}
            onChange={(event) => {
              handleTextareaChange(event)
              onChange!(event)
            }}
            onClick={onClick}
            required={required}
            placeholder={placeholder}
            value={value}
            maxLength={maxLength}
            inputMode={inputMode}
            className="block w-full resize-none disabled:bg-gray-200 appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primary-blue focus:outline-none focus:ring-primary-blue sm:text-sm"
          />
          : <input
            ref={ref}
            step={step}
            readOnly={isReadOnly}
            disabled={disabled}
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            onClick={onClick}
            required={required}
            placeholder={placeholder}
            type={type}
            value={value}
            maxLength={maxLength}
            max={max}
            min={min}
            inputMode={inputMode}
            className="block w-full disabled:bg-gray-200 appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primary-blue focus:outline-none focus:ring-primary-blue sm:text-sm"
          />
        }
      </div>
      <div>
        {
          error &&
          <FormError text={error} />
        }
      </div>
    </div>
  )
})


export default Input