import { useMutation } from '@apollo/client'
import { LOGOUT } from '@ec/apollo/src/mutations/auth'
import { Button, Toast } from '@ec/ui'
import { useEffect, useState } from 'react'
import { useNotifier } from 'react-headless-notifier'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { logout } from 'slices/auth'
import { RootState } from 'store'
import Navbar from '@ec/ui/src/components/Navbar'
import Logo from '@ec/ui/src/svgs/logo'


const AuthNavbar = () => {
  const { notify } = useNotifier()
  const dispatch = useDispatch()

  const [accountSettingsURL, setAccountSettingsURL] = useState('/account/settings')

  const { user } = useSelector((state: RootState) => ({
    user: state.auth.user,
  }))

  const [logoutMutation, { data: logoutData }] = useMutation(LOGOUT)

  useEffect(() => {
    if (logoutData) {
      notify(<Toast type='info' message='You have signed out successfully' />)
      dispatch(logout())
    }
  }, [logoutData])

  const volunteerLinks: { url: string, text: string, root?: string }[] = [
    { url: '/dashboard', text: 'Dashboard' },
    { url: '/dashboard/opportunities', text: 'Your Opportunities', root: '/dashboard/opportunities' },
    { url: `/profile/${user!.id}`, text: 'My Profile' },
  ]

  const altLinks: { url: string, text: string, root?: string }[] = [
    { url: '/', text: 'Opportunities', root: '/opportunities/' },
  ]

  useEffect(() => {
    switch (user?.context) {
      case 'VOLUNTEER':
        setAccountSettingsURL('/account/settings')
        break

      case 'VOLUNTEER_MANAGER':
        setAccountSettingsURL(`${process.env.REACT_APP_VOLUNTEER_MANAGER_PORTAL_URL}/account/settings`)
        break

      case 'EMPLOYER':
        setAccountSettingsURL(`${process.env.REACT_APP_EMPLOYER_PORTAL_URL}/account/settings`)
        break

      default:
        break
    }
  }, [user])

  return (
    <Navbar
      logo={
        <Link to="/" className="block max-w-[180px] lg:max-w-[260px] w-full">
          <Logo className="w-full" />
        </Link>
      }
      links={user?.context === 'VOLUNTEER' ? volunteerLinks : altLinks}
      actions={
        <>
          {user &&
            <>
              {
                user.context === 'VOLUNTEER_MANAGER' &&
                <div className="">
                  <a href={`${process.env.REACT_APP_VOLUNTEER_MANAGER_PORTAL_URL}`}>
                    <Button type="button" variant="primary-alt" >
                      Go To Dashboard
                    </Button>
                  </a>
                </div>
              }

              <Navbar.Profile
                user={user}
                links={[{
                  url: accountSettingsURL,
                  text: 'Account Settings',
                  anchorTag: Boolean(user?.context !== 'VOLUNTEER'),
                }]}
                logout={logoutMutation} />
            </>
          }
        </>
      }
      persistActions={true}
    />
  )
}

export default AuthNavbar