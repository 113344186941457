import { Opportunity } from '@ec/types'
import { FacebookIcon, TwitterIcon, LinkedinIcon } from '@ec/ui'

type propTypes = {
  opportunityLink?: Location,
  opportunity: Opportunity,
}

const SocialBar = ({ opportunityLink = window.location, opportunity }: propTypes) => {
  const iconClassName = 'w-6 h-6'
  // 225 is max length for twitter minus the other text
  const twitterText = `Check out this opportunity ${opportunity.title.length < 225 ? `for ${opportunity.title}` : '' } via Go Volunteering!`
  const twitterLink = `https://twitter.com/intent/tweet?text=${twitterText}'&url=${opportunityLink}`
  const facebookLink = 'https://facebook.com/sharer/sharer.php?u=' + opportunityLink
  const linkedinLink = 'https://www.linkedin.com/sharing/share-offsite/?url=' + opportunityLink

  return (
    <div className="flex gap-2.5 py-2.5 items-center">
      <a href={facebookLink} target='_blank' rel='noreferrer'>
        <FacebookIcon className={iconClassName}/>
      </a>
      
      <a href={twitterLink} target='_blank' rel='noreferrer'>
        <TwitterIcon className={'w-5 h-5'} />
      </a>

      <a href={linkedinLink} target='_blank' rel='noreferrer'>
        <LinkedinIcon className={iconClassName} />
      </a>
    </div>
  )
}

export default SocialBar
