import { Opportunity } from '@ec/types'
import Button from '../../../components/Button'
import { Title } from '../../../components/Headings'
import Skeleton from 'react-loading-skeleton'
import MapPinIcon from '../../../icons/mapPinIcon'
import { shortAddress } from '../../../helpers/strings/address'
import { Tooltip } from 'react-tooltip'

type PropTypes = {
  opportunity?: Opportunity,
  isLoading?: boolean,
  applyDisabled?: boolean,
  disabledMessage: string | undefined,
  handleApply: () => void
}

const OpportunityTitleBanner = ({
  opportunity,
  isLoading = false,
  applyDisabled = false,
  disabledMessage = 'You cannot apply for this opportunity.',
  handleApply,
}: PropTypes) => {
  const addressDetails = {
    street_address: opportunity?.street_address,
    city: opportunity?.city,
    postcode: opportunity?.postcode,
  }
  
  return (
    <div className="w-full">
      <div className="flex lg:justify-between lg:items-center">
        <div className="w-full">
          {
            isLoading
              ? <Skeleton height={33} className="mt-4 mb-2 max-w-[300px]" />
              : <Title className="lg:text-3xl text-xl !m-0 !leading-[50px]">{opportunity?.title}</Title>
          }
        </div>
        <div className="lg:flex hidden">
          { disabledMessage && applyDisabled && <Tooltip anchorId={`apply-button-${opportunity?.id}`} content={disabledMessage} style={{maxWidth:'20rem'}}/> }
          <Button
            id={`apply-button-${opportunity?.id}`}
            isLoading={isLoading}
            onClick={handleApply}
            disabled={applyDisabled}
          >
            Apply Now
          </Button>
        </div>
      </div>
      {
        isLoading
          ? <Skeleton width={300} height={24} />
          : <div className="flex items-center text-primary-blue -ml-1">
            <MapPinIcon className="w-6 h-6" />
            <p className="ml-1 text-lg leading-5">{shortAddress(addressDetails)}</p>
          </div>
      }
    </div>
  )
}

export default OpportunityTitleBanner